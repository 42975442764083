import logo from "../../../images/Fine Pharma2.png";

const AdressFooter = () => {
  return (
    <div className="footer__content--address">
      <div className="logo">
        <img src={logo} alt="" />
      </div>
      <h3 className='mb-4 t-uppercase'>Address</h3>
      <p>111, Shahid Tajuddin Ahmed Sharani, Moughbazar, Dhaka</p>
      <p className="phone">
        <strong className='t-uppercase'>Phone: </strong>+8801319719753
      </p>
      <p className="mail">
        <strong className='t-uppercase'>E-mail: </strong>finepharmaltd@gmail.com
      </p>
    </div>
  );
};

export default AdressFooter;
