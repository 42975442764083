import { useCallback } from "react";

const useGoTop = () => {
   

    const goTop = useCallback(({topOffset = 0, element, smooth = true}) => {
        element = element || document.querySelector('body');
        window.scrollTo({ top: element.offsetTop + topOffset, behavior: smooth ? 'smooth' : '' });
    }, []);

    return [goTop]
}

export default useGoTop;