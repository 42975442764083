import { createContext } from "react";

const AppContext = createContext({
  wishlist: {
    storeProducts: (listOfId) => { },
    removeProducts: () => {},
    addProduct: (id) => { },
    removeProduct: (id) => { },
    products: []
  },
  userInfo: {
    initCount: (count) => {},
    destroyCount: () => {},
    update: (noticeCount, pendingCash, fineCash) => {},
    destroy: () => {},
    fineCash: 0,
    pendingCash: 0,
    noticeCount: 0
  },
  searchQuery: {
    storeSearchQuery: (text = '') => {},
    searchQuery: ''
  }
});

export default AppContext;