import { useCallback, useReducer } from "react";
import CheckoutContext from "./checkout-context";

const initialState = {
  summaryIsValid: true,
  addressIsValid: false,
  paymentIsValid: false,
  address: {
    id: "",
    districtId: "",
    type: "",
    fullAddress: {},
  },
  deliveryCharge: 0,
  payOption: "",
  from: "Cart Order",
  autoOrderIsActive: false,
  remarks: "",
  prescriptions: [],
  coupon: { id: "", code: "", discountAmount: 0, disountType: "" },
};

const checkoutReducer = (state, action) => {
  if (action.type === "AUTO_ORDER_CHANGED") {
    return {
      ...state,
      autoOrderIsActive: action.isActive,
    };
  }

  if (action.type === "ADDRESS_CHANGED") {
    return {
      ...state,
      address: {
        ...action.address,
      },
      addressIsValid:
        (action.address.id && action.address.districtId),
    };
  }

  if (action.type === "PAY_OPTION_CHANGED") {
    return {
      ...state,
      payOption: action.payOption,
      paymentIsValid: action.payOption !== "",
    };
  }

  if (action.type === "REMARKS_CHANGED") {
    return {
      ...state,
      remarks: action.remarks,
    };
  }

  if (action.type === "DELIVERY_CHARGE_CHANGED") {
    return {
      ...state,
      deliveryCharge: action.cost,
    };
  }

  if (action.type === "STORE_COUPON") {
    return {
      ...state,
      coupon: { ...action.coupon },
    };
  }

  if (action.type === "ADD_NEW_PRESCRIPTION") {
    return {
      ...state,
      prescriptions: [
        ...state.prescriptions,
        { ...action.file },
      ],
    };
  }

  if (action.type === "REMOVE_EXISTING_PRESCRIPTION") {
    return {
      ...state,
      prescriptions: state.prescriptions.filter(p => p.id !== action.id)
    };
  }

  if(action.type === 'RESET_CHECKOUT_CONTEXT'){
    return initialState;
  }

  return initialState;
};

const CheckoutContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(checkoutReducer, initialState);

  const autoOrderChangeHandler = (isActive) => {
    dispatch({ type: "AUTO_ORDER_CHANGED", isActive });
  };

  const addressSelectHandler = useCallback((id, districtId, type, fullAddress) => {
    dispatch({
      type: "ADDRESS_CHANGED",
      address: { id, districtId, type, fullAddress },
    });
  }, []);

  const payOptionChangeHandler = (payOption) => {
    dispatch({ type: "PAY_OPTION_CHANGED", payOption });
  };

  const remarksChangeHandler = (remarks) => {
    dispatch({ type: "REMARKS_CHANGED", remarks });
  };

  const deliveryChangeHandler = useCallback((cost) => {
    dispatch({ type: "DELIVERY_CHARGE_CHANGED", cost });
  }, []);

  const storeCouponHandler = (coupon) => {
    dispatch({ type: "STORE_COUPON", coupon });
  };

  const storePrescription = (file) => {
    dispatch({ type: "ADD_NEW_PRESCRIPTION", file });
  };

  const removePresciption = (id) => {
    dispatch({ type: "REMOVE_EXISTING_PRESCRIPTION", id });
  };

  const resetCheckout = () => {
    dispatch({ type: "RESET_CHECKOUT_CONTEXT"});
  }

  const cartContext = {
    prescriptions: state.prescriptions,
    storePrescription,
    removePresciption,
    autoOrderIsActive: state.autoOrderIsActive,
    autoOrderChangeHandler,
    address: state.address,
    addressSelectHandler,
    deliveryCharge: state.deliveryCharge,
    onDeliveryChangeChange: deliveryChangeHandler,
    payOption: state.payOption,
    onPayOptionChange: payOptionChangeHandler,
    remarks: state.remarks,
    onRemarksChange: remarksChangeHandler,
    summaryIsValid: state.summaryIsValid,
    addressIsValid: state.addressIsValid,
    paymentIsValid: state.paymentIsValid,
    storeCoupon: storeCouponHandler,
    coupon: state.coupon,
    resetCheckout
  };

  return (
    <CheckoutContext.Provider value={cartContext}>
      {children}
    </CheckoutContext.Provider>
  );
};

export default CheckoutContextProvider;
