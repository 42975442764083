import React, { useCallback, useEffect, useState } from "react";
import { storageDict } from "../services/data-service";

const AuthContext = React.createContext({
  isLoggedIn: false,
  onLogout: () => {},
  onLogin: (user) => {},
  updateUser: (user) => {},
  updateImage: (image) => {},
  user: {},
  getUserAsync: () => {},
});

export const AuthContextProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [user, setUser] = useState(null);

  const getUserAsync = useCallback(async () => {
    let loginUser = await localStorage.getItem(storageDict.user);

    if (loginUser) {
      loginUser = await JSON.parse(loginUser);
    }

    return loginUser;
  }, []);

  useEffect(() => {
    let existingUser = localStorage.getItem(storageDict.user);

    if (existingUser) {
      existingUser = JSON.parse(existingUser);

      if (!existingUser.token) {
        logoutHandler();
      } else {
        setUser(existingUser);
        setIsLoggedIn(true);
      }
    }
  }, [isLoggedIn]);

  const logoutHandler = () => {
    localStorage.removeItem(storageDict.user);

    setIsLoggedIn(false);
  };

  const loginHandler = useCallback((newUser) => {
    if (newUser.IsError) {
      return false;
    }

    localStorage.setItem(
      storageDict.user,
      JSON.stringify({
        id: newUser.Id,
        name: newUser.Data.Name,
        email: newUser.Data.Email,
        image: newUser.Data.Icon,
        phone: newUser.Data.Phone,
        token: newUser.Data.datacontent,
      })
    );
    setUser({
      id: newUser.Id,
      name: newUser.Data.Name,
      email: newUser.Data.Email,
      image: newUser.Data.Icon,
      phone: newUser.Data.Phone,
      token: newUser.Data.datacontent,
    });
    setIsLoggedIn(true);
  }, []);

  const userUpdateHandler = useCallback((userData) => {
    let user = localStorage.getItem(storageDict.user);
    if (user) {
      user = JSON.parse(user);
      user.name = userData.name;
      user.email = userData.email;

      localStorage.setItem(storageDict.user, JSON.stringify(user));
    }
    setIsLoggedIn(true);
    setUser((prevState) => ({
      ...prevState,
      name: userData.name,
      email: userData.email,
    }));
  }, []);

  const userImageUpdateHandler = useCallback((image) => {
    let user = localStorage.getItem(storageDict.user);
    if (user) {
      user = JSON.parse(user);
      user.image = image;

      localStorage.setItem(storageDict.user, JSON.stringify(user));
    }
    setIsLoggedIn(true);
    setUser((prevState) => ({
      ...prevState,
      image: image
    }));
  }, []);

  
  useEffect(()=>{
    localStorage.removeItem(storageDict.prevUser);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        onLogout: logoutHandler,
        onLogin: loginHandler,
        updateUser: userUpdateHandler,
        updateImage: userImageUpdateHandler,
        user: user,
        getUserAsync,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
