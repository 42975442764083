import { useHistory } from "react-router-dom";
import Button from "../../../utilities/Button/Button";
import cart from "../../../../icons/cart-filled.svg";
import stockOut from "../../../../icons/stock-out.svg";
import { imgType, productImg } from "../../../../services/ImgService";
import { Fragment, useContext, useRef, useState } from "react";
import CartContext from "../../../../store/cart-context";
import AppContext from "../../../../store/app-context";
import AnimatedProduct from "../../../AnimatedProduct/AnimatedProduct";

const SearchedProduct = ({ product, onClick }) => {
  const [anime, setAnime] = useState(false);
  const cardRef = useRef();
  const cartCtx = useContext(CartContext);
  const history = useHistory();
  const { searchQuery } = useContext(AppContext);

  const addToCart = (e) => {
    e.stopPropagation();

    setAnime(true);

    cartCtx.addProduct({
      id: product.Id,
      name: product.Name,
      genericName: product.GenericName,
      strength: product.Strength,
      price: product.UnitSalePrice,
      discount: product.Discount,
      imgName: product.ImagePath,
      imgTypeCode: product.ImgType,
      supplier: product.Supplier,
      quantity: 1,
      category: product.Category,
      isPrescriptionRequired: product.IsPrescriptionRequired,
    });
  };

  const clickHandler = () => {
    history.push(`/product/details/${product.Id}`);
    onClick();
  };

  const stopAnime = () => {
    setAnime(false);
  };

  const getHTML = () => {
    return {
      __html: product.Name.toLowerCase().replace(
        searchQuery.searchQuery.toLowerCase(),
        `<span class="t-black t-bolder">${searchQuery.searchQuery}</span>`
      ),
    };
  };

  return (
    <Fragment>
      <div ref={cardRef}>
        <div className="result-card pointer" onClick={clickHandler}>
          <div className="result-card__img">
            <img
              src={productImg(product.ImagePath, product.ImageType, imgType.sm)}
              alt=""
            />
          </div>
          <div className="t-16 mb-4">
            <h4 className="t-bold t-black--lite t-hover-underline t-uppercase">
              <span dangerouslySetInnerHTML={getHTML()}></span>{" "}
              {product.Strength} {product.Category}
            </h4>
            {product.IsPrescriptionRequired && (
              <p className="t-12 t-secondary line-height">
                Prescription Required
              </p>
            )}
            <p className="t-12 t-lead t-lowercase mb-4">
              {product.GenericName}{" "}
            </p>
            <p className="t-12 mb-8 line-height">{product.Suplier}</p>
            <p className="t-14 t-bold">
              <span>Price: </span>
              <span className="current mr-4">
                {product.UnitSalePrice - product.Discount}
                <span>Tk</span>
              </span>
              {product.Discount > 0 && (
                <span className="t-12 t-secondary t-line-thro">
                  {product.UnitSalePrice}Tk
                </span>
              )}
            </p>
          </div>
          <div className="result-card__details--actions">
            {product.IsOutOfStock && <button className="out-of-stock">
            <img src={stockOut} alt="" className="w-20" />
              Out Of Stock</button>}
            {!product.IsOutOfStock && (
              <Button
                click={addToCart}
                icon={cart}
                text={"Add to Cart"}
                options={{
                  type: "primary",
                  style: "fill",
                }}
              />
            )}
          </div>
        </div>
      </div>
      <AnimatedProduct when={anime} onStop={stopAnime} uiRef={cardRef} />
    </Fragment>
  );
};

export default SearchedProduct;
