import { Fragment, useEffect } from "react";
import { createPortal } from "react-dom";
import classes from "./Alert.module.css";

const ICONS = {};

const Alert = ({ text, onClose = () => {}, type, fallback = () => {} }) => {
  const alertEl = document.querySelector("#alert");

  useEffect(() => {
    document.querySelector("body").style.position = "fixed";

    return () => {
      document.querySelector("body").style.position = "unset";
    };
  }, []);

  const closeHandler = () => {
    fallback();
    onClose();
  };

  return (
    <Fragment>
      {createPortal(
        <div className={classes.overlay}>
          <div className={`${classes.alert} ${classes[type]}`}>
            <div>
              <div className={`${classes.icon}`}>{ICONS[type]}</div>
              <p>{text}</p>
            </div>
            <button onClick={closeHandler}>Ok</button>
          </div>
        </div>,
        alertEl
      )}
    </Fragment>
  );
};

export default Alert;
