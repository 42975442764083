import NavTop from "./NavTop/NavTop";
import NavMid from "./NavMid/NavMid";
import NavLow from "./NavLow/NavLow";
import { forwardRef, useCallback, useEffect, useRef} from "react";

const MainHeader = forwardRef((props, stickyNavRef) => {
  const navMidRef = useRef();
  const navTopRef = useRef();

  const handleScroll = useCallback(() => {
    const offset=window.scrollY;
    const navTopHeight = navTopRef.current.clientHeight;
    const span = window.innerWidth <= 1000 ? `.5rem` : `1.25rem`


    if(offset >  navTopHeight){
      stickyNavRef.current.style.top = `${-navTopHeight}px`;
      navMidRef.current.style.paddingTop = `.5rem`;
      navMidRef.current.style.paddingBottom = `.5rem`;
    }
    else if (offset < navTopHeight){
      stickyNavRef.current.style.top = `${0}px`;
      navMidRef.current.style.paddingTop = span;
      navMidRef.current.style.paddingBottom = span;
    }
  }, [stickyNavRef]);


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  return (
    <header className='sticky_navs' ref={stickyNavRef}>
      <NavTop ref={navTopRef}/>
      <NavMid ref={navMidRef}/>
      <NavLow />
    </header>
  );
});

export default MainHeader;
