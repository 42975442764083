import phone from './phone.svg';
import mail from './mail.svg';
import ham from './e-hamburger-black.svg';
import document from './document.svg';
import support from './support.svg';
import heart from './black-outline-heart.svg';
import bell from './e-bell.svg';
import user from './e-user.svg';
import search from './e-search.svg';

const icons = {
    phone,
    mail,
    ham,
    document,
    support,
    heart,
    bell,
    user,
    search
}

export default icons;