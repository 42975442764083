import { NavLink } from "react-router-dom";

const NavLowLink = ({ link }) => {
  const capitalize = (text) => {
    return text
      .toLowerCase()
      .replace(/(^|\s)\S/g, (letter) => letter.toUpperCase());
  };

  return (
    <NavLink
      to={link.to}
      activeClassName="nav-low__link--active"
      className="nav-bottom__items__links--link active-nav"
      exact
    >
      {capitalize(link.text)}
    </NavLink>
  );
};

export default NavLowLink;
