import { orderStatus } from "../services/data-service";

const productArrayToObj = (product) => {
  const [
    id,
    name,
    genericName,
    strength,
    price,
    discount,
    img,
    imgCode,
    type,
    company,
  ] = product;
  return {
    id,
    name,
    genericName,
    strength,
    price,
    discount,
    img,
    imgCode,
    type,
    company
  }

}



const sumAProperty = (items = [], path) => {
  return items.reduce((total, product) => (+product[path] + total), 0);
}

const calcMRP = (price, discount) => {
  return price-discount;
}

const calcAmount = (price, discount, quantity) => {
  return quantity * calcMRP(price, discount);
}

const unifyOrders = (orders) => {
  const unifiedOrders = {};

  for (const [key, value] of Object.entries(orderStatus)) {
    unifiedOrders[key] =
      (orders.filter(order => order.Status.toLowerCase() === value.toLowerCase()));
  }

  return unifiedOrders;
}

const humanizeFullDateTime = (enData) => {
  const regex = /\d+/g
  const timestamp = enData.match(regex).join('');

  if (typeof +timestamp === 'number') {
    const date = new Date(+timestamp);

    return `${date.toLocaleString('en-US', { dateStyle: 'full' })} ${date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`
  }

  return 'Error parsing date';
}

const humanizeShortDateTime = (enData) => {
  const regex = /\d+/g
  const timestamp = (enData.match(regex) || []).join('');

  if (typeof +timestamp === 'number') {
    const date = new Date(+timestamp);

    return `${date.toLocaleString('en-GB', { dateStyle: 'short' })} ${date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`
  }

  return 'Error parsing date';
}

const humanizeFullDate = (enData) => {
  const regex = /\d+/g
  const timestamp = (enData.match(regex) || []).join('');

  if (typeof +timestamp === 'number') {
    const date = new Date(+timestamp);

    return `${date.toLocaleString('en-US', { dateStyle: 'long' })}`
  }

  return 'Error parsing date';
}

const isEmpty = (text = '') => {
  return text.length === 0
}

const calcCouponPercent = (discount, amount) => {
  return (discount / amount) * 100;
}

const requestOTP = {
  registration: 'registration',
  resetPassword: 'resetPassword'
}

function isInViewPort(element, offset) {
  var bounding = element.getBoundingClientRect();

  if (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
    bounding.bottom - offset <= (window.innerHeight || document.documentElement.clientHeight)
  ) {
    return true;
  } else {
    return false;
  }
}

const isExist = (list, value, path) => {
  const existings = list.find(item => item[path] === value);

  return existings ? true : false;
}

const paramsUrlGenerator = (params) => {
  const url = '?';
  const paramsStrings = [];

  for (const [key, value] of Object.entries(params)) {
    paramsStrings.push(`${key}=${value}`);
  }

  return url + paramsStrings.join('&');
}

const tranformQuery = (text, func) => {
  const duplicateText = text;
  var query = [];
  text.split(' ').each(function () {
    text = (this + '').trim();
    if (text && text.length > 0) {
      query.push(text);
    }
  });


  if (duplicateText[duplicateText.length - 1] === ' ') {
    query[query.length - 1] += ' ';
  }
  console.log(query)
  return query;
}

export {
  productArrayToObj,
  sumAProperty,
  calcMRP,
  calcAmount,
  unifyOrders,
  humanizeFullDateTime,
  humanizeShortDateTime,
  humanizeFullDate,
  isEmpty,
  calcCouponPercent,
  requestOTP,
  isInViewPort,
  isExist,
  paramsUrlGenerator,
  tranformQuery
}