import { Fragment, useState } from "react";
import Page from "../../Page/Page";
import AuthForms from "../AuthForms/AuthForms";

const Unauthenticate = ({ navigateTo }) => {
  const [authFormIsOpen, setAuthFormIsOpen] = useState(false);
  const openAuthFormHandler = () => {
    setAuthFormIsOpen(true);
  };

  const closeHandler = () => {
    setAuthFormIsOpen(false);
  };

  return (
    <Fragment>
      <Page className="container center">
        <div className="t-center not-found fade-in">
          <h1 className="t-secondary mb-12">Unauthorize</h1>
          <h3
            onClick={openAuthFormHandler}
            className="t-primary t-hover-underline pointer"
          >
            Login to visit the page
          </h3>
        </div>
      </Page>
      {authFormIsOpen && (
        <AuthForms
          onClose={closeHandler}
          onLoginNavigate={navigateTo}
          onLoginSuccess={closeHandler}
        />
      )}
    </Fragment>
  );
};

export default Unauthenticate;
