import logo from "../../../images/Fine Pharma3.png";
import { Link } from "react-router-dom";
import NavMidLinks from "./NavMidLinks/NavMidLinks";
import NavMidSearchBar from "./NavMidSearchBar/NavMidSearchBar";
import { SearchContextProvider } from "../../../store/search-context";
import Sidebar from "../../Sidebar/Sidebar";
import { forwardRef } from "react";

const NavMid = forwardRef((props, ref) => {

  return (
    <section className="bg-nav-mid" id="mid-nav">
      <nav className="nav-mid" ref={ref}>
        <div className="nav-mid__logo">
          {/* <Sidebar /> */}
          <Link className="logo-container block pointer" to="/">
            <img src={logo} alt="fine pharma logo" />
          </Link>
          <div className="mobile-nav-mid-links">
            <NavMidLinks />
          </div>
        </div>
        <div className="flex w-full justify-center">
          <Sidebar />
          <SearchContextProvider>
            <NavMidSearchBar />
          </SearchContextProvider>
        </div>

        <div className="mid-nav-mid__links">
          <NavMidLinks />
        </div>
      </nav>
    </section>
  );
});

export default NavMid;
