import { useState } from "react";
import "./CategoryToggler.css";
import Categories from "../Categories/Categories";
import downAngle from "../../../../icons/e-down-angle.svg";

const CategoryToggler = () => {
  const [isOpened, setIsOpened] = useState(false);

  const openCategories = () => {
    setIsOpened(true);
  };

  const hideCategories = () => {
    setIsOpened(false);
  };

  return (
    <div
      className="category-toggler"
      onMouseEnter={openCategories}
      onMouseLeave={hideCategories}
    >
      <div className="flex justify-between align-center">
        <div>CATEGORIES</div>
        <div>
          <img src={downAngle} alt="" />
        </div>
      </div>
      {isOpened && (
        <div className="categories-pop-over">
          <Categories />
        </div>
      )}
    </div>
  );
};

export default CategoryToggler;
