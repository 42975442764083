import { useCallback, useReducer } from "react";
import AppContext from "./app-context";

const initialWishlistState = {
  products: { count: 0 },
};

const initialUserInfo = {
  noticeCount: 0,
  pendingCash: 0,
  fineCash: 0,
};

const initialSearchQuery = {
  searchQuery: ''
};

const wishlistReducer = (state, action) => {
  if (action.type === "STORE_WISHLISTED_PRODUCT_ID") {
    return {
      ...state,
      products: action.listOfId,
    };
  }

  if (action.type === "ADD_TO_WISHLIST") {
    const products = { ...state.products };
    products[action.id] = action.id;
    products.count += 1;

    return {
      ...state,
      products,
    };
  }

  if (action.type === "REMOVE_FROM_WISHLIST") {
    const products = { ...state.products };
    products[action.id] = false;
    products.count -= 1;

    return {
      ...state,
      products,
    };
  }

  if (action.type === "REMOVE_ALL_WISHLIST") {
    return initialWishlistState;
  }

  return initialWishlistState;
};

const userInfoReducer = (state, action) => {
  if (action.type === "INITIAL_NOTIFICATION_COUNT") {
    return {
      ...state,
      count: action.count,
    };
  }

  if (action.type === "DESTROY_NOTIFICATION_COUNT") {
    return {
      ...state,
      noticeCount: 0,
    };
  }

  if (action.type === "UPDATE_USER_INFO") {
    return {
      ...state,
      ...action.userInfo,
    };
  }

  if (action.type === "DESTROY_USER_INFO") {
    return initialUserInfo;
  }

  return initialUserInfo;
};

const productAnimeReducer = (state, action) => {
  if(action.type === 'STORE_SEARCH_QUERY'){
    return {
      searchQuery: action.searchQuery
    }
  }

  return initialSearchQuery;
}

const AppContextProvider = ({ children }) => {
  const [wishlistState, dispatchWishlist] = useReducer(
    wishlistReducer,
    initialWishlistState
  );

  const [userInfoState, dispatchUserInfo] = useReducer(
    userInfoReducer,
    initialUserInfo
  );

  const [searchQueryState, dispatchSearchQuery] = useReducer(
    productAnimeReducer,
    initialSearchQuery
  )

  //#region wishlist
  const storeWishlistedProducts = useCallback((listOfId) => {
    dispatchWishlist({ type: "STORE_WISHLISTED_PRODUCT_ID", listOfId });
  }, []);

  const addToWishlist = (id) => {
    dispatchWishlist({ type: "ADD_TO_WISHLIST", id });
  };

  const removeFromWishlist = (id) => {
    dispatchWishlist({ type: "REMOVE_FROM_WISHLIST", id });
  };

  const removeAllWishlist = useCallback(() => {
    dispatchWishlist({ type: "REMOVE_ALL_WISHLIST" });
  }, []);
  //#endregion wishlist

  //#region notification
  const initCount = useCallback((count) => {
    dispatchUserInfo({ type: "INITIAL_NOTIFICATION_COUNT", count });
  }, []);

  const destroyCount = useCallback(() => {
    dispatchUserInfo({ type: "DESTROY_NOTIFICATION_COUNT" });
  }, []);

  const update = useCallback((noticeCount, pendingCash, fineCash) => {
    dispatchUserInfo({
      type: "UPDATE_USER_INFO",
      userInfo: { noticeCount, pendingCash, fineCash },
    });
  }, []);

  const destroy = useCallback(() => {
    dispatchUserInfo({ type: "DESTROY_USER_INFO" });
  }, []);
  //#endregion notification

  const storeSearchQuery = (text) => {
    dispatchSearchQuery({type: 'STORE_SEARCH_QUERY', searchQuery: text});
  }

  const wishlistContext = {
    storeProducts: storeWishlistedProducts,
    removeProducts: removeAllWishlist,
    addProduct: addToWishlist,
    removeProduct: removeFromWishlist,
    products: wishlistState.products,
  };

  const userInfo = {
    initCount,
    destroyCount,
    update,
    destroy,
    pendingCash: userInfoState.pendingCash,
    fineCash: userInfoState.fineCash,
    noticeCount: userInfoState.noticeCount,
  };

  const searchQuery = {
    storeSearchQuery,
    searchQuery: searchQueryState.searchQuery
  } 

  const context = {
    wishlist: { ...wishlistContext },
    userInfo: { ...userInfo },
    searchQuery: { ...searchQuery }
  };


  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
