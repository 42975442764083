import { Fragment, useContext, useState } from "react";
import SearchContext from "../../../../store/search-context";
import Button from "../../Button/Button";

const SearchField = ({ placeholderText, onChange, buttonSettings }) => {
  const searchCtx = useContext(SearchContext);
  const [value, setValue] = useState("");

  const onInputChangeHandler = (e) => {
    if (e.target.value) searchCtx.show();
    onChange(e.target.value);
    setValue(e.target.value);
  };

  const focusHandler = (e) => {
    onChange(value);
    if (value) searchCtx.show();
  };

  return (
    <Fragment>
      <input
        type="text"
        name="query"
        placeholder={placeholderText}
        onChange={onInputChangeHandler}
        onFocus={focusHandler}
        value={value}
        autoComplete="off"
      />
      {buttonSettings && (
        <Button
          icon={buttonSettings.icon}
          text={buttonSettings.text}
          isActive={buttonSettings.isActive}
          options={buttonSettings.options}
        />
      )}
    </Fragment>
  );
};

export default SearchField;