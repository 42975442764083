const baseUrl = window.BaseUrl;

const imgType = { sm: 'Small', icon: 'Icon', origin: 'Orginal' }

const productImg = (path, pathType, size = imgType.sm, dflt) => {


    var src = '/Content/ImageData/Products/' + size + '/'
    if (path && path.length > 30) {
        if (pathType === 1) {
            src += path;
        } else {
            src = '/Content/ImageData/ProductCategory/' + size + '/' + path;
        }
    } else {
        return dflt;
    }
    return baseUrl+src;
}
const bannerImg = (path, size, dflt) => {

    size = size || imgType.sm;

    var src = '/Content/ImageData/Banner/' + size + '/'+path;
    return baseUrl+src;
}
///Content/ImageData/Banner/Orginal/

const imgUrl = (add) => {
    return baseUrl + add;
}

export {
    imgType, productImg, bannerImg,baseUrl, imgUrl
}