import { useContext } from "react";
import cart from "../../../icons/cart-filled.svg";
import CartContext from "../../../store/cart-context";

const CloseCart = ({openCart}) => {
  const cartCtx = useContext(CartContext);
  
  return (
    <div className="cart--compact" onClick={openCart}>
      <div className="cart--compact__item-count">
        Items <span>{cartCtx.products.length}</span>
      </div>
      <div className="cart--compact__icon">
        <img src={cart} alt="" />
      </div>
      <div className="cart--compact__amount">
      <span>{cartCtx.discountedAmount?.toMoney()}</span>Tk
      </div>
    </div>
  );
};

export default CloseCart;
