import React from "react";
import { Link } from "react-router-dom";

export const FooterLinks = () => {
  return (
    <div className="footer__content--links">
      <h3 className="t-uppercase mb-12">Useful Links</h3>
      <ul>
        <li>
          <Link to="/Privacy-policy">Privacy Policy</Link>
        </li>
        <li>
          <Link to="/Contact">Contact Us</Link>
        </li>
        <li>
          <Link to="/terms-and-conditions">Terms And Condition</Link>
        </li>
        <li>
          <Link to="/Return-policy">রিফান্ড ও রিপ্লেসমেন্ট পলিসি</Link>
        </li>
        <li>
          <Link to="/How-It-Works">কিভাবে অর্ডার করবেন</Link>
        </li>
      </ul>
    </div>
  );
};
