import { storageDict } from "../services/data-service";

const getToken = async () => {
    let user = localStorage.getItem(storageDict.user);
    if(user){
        return JSON.parse(user).token;
    }

    return '';
}

export const getTokenSync =  () => {
    let user = localStorage.getItem(storageDict.user);
    if(user){
        return JSON.parse(user).token;
    }

    return '';
}

export default getToken;

