import Slider from '../utilities/Slider/Slider';
import PerksTemplate from './PerksTemplate/PerksTemplate';
import perkLogo1 from "../../icons/pres2.svg";
import perkLogo2 from "../../icons/emergency2.svg";
import perkLogo3 from "../../icons/offer.svg";
import perkLogo4 from "../../icons/video.svg";
import './Perks.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Fragment, useState } from 'react';
import Popup from "../../components/utilities/Popup/Popup";
import CallForOrder from '../Popups/PrescriptionSaved/CallForOrder';



const Perks = () => {
  const history = useHistory();
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  
  const perks = [
    {
      id: 1,
      logo: perkLogo1,
      title: "Upload your Prescription",
      description: "Just upload prescription to place an order",
      to: "/profile/prescription",
      fun: () => {
        history.push("/profile/prescription");
      },
    },
    {
      id: 2,
      logo: perkLogo2,
      title: "Call For Order",
      description: (
        <span>
          Call us anytime to order your medicine:{" "}
          <a
            className="t-secondary t-hover-underline"
            href="tel:+8801319719753"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            +8801319719753
          </a>
        </span>
      ),
      fun: () => {
        setIsPopupVisible(true);
      },
    },
    {
      id: 3,
      logo: perkLogo3,
      title: "Special Offer",
      description: "Check our offers here, save your money ",
      to: "/products/offers",
      fun: () => {
        history.push("/products/offers");
      },
    },
    {
      id: 4,
      logo: perkLogo4,
      title: "How to Purchase",
      description: "Watch videos here, How to order in Fine Pharma.",
      fun: () => {},
    },
  ];

  const options = {
    rewind: true,
    type: 'slide',
    autoplay: false,
    rewindSpeed: 1000,
    speed: 1000,
    resetProgress: true,
    perPage: 4,
    perMove: 2,
    gap: '1rem',
    breakpoints: {
      1000: {
        perPage: 2,
        autoplay: true
      },
      500: {
          perPage: 1,
          autoplay: true
      }
    },
  };

  options.autoplay = Perks.length > options.perPage;

  const closeHandler = () => {
    setIsPopupVisible(false);
  };

  return (
    <Fragment>
      <section className='bg-perks'>
      <div className='perks pb-16'>
        <Slider items={perks} options={options} Template={PerksTemplate} />
      </div>
    </section>
      {isPopupVisible && (
        <Popup title="Call For Order" onClose={closeHandler}>
          <CallForOrder onOk={closeHandler} />
        </Popup>
      )}
    </Fragment>
    
  );
};

export default Perks;
