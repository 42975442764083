import getToken from "../helpers/token";

const BASE_URL = window.BaseUrl;


export async function getDistricts(payload) {
    const res = await fetch(`/AddressArea/District/AutoComplete`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'datacontent': await getToken()

        },
    });
    const data = await res.json();

    if (!res.ok) {
        throw new Error(data.message || 'Could not fetch location data');
    }

    if (res.ok && data.IsError) {
        throw new Error('User defined errors');
    }

    const transformedDistricts = [];

    data.Data.Data[0].forEach(district => {
        transformedDistricts.push({
            id: district[1],
            name: district[0],
            charge: district[2],
            maxAmount: district[3],
            minCharge: district[4]
        });
    })

    return transformedDistricts;
}

export async function getUpzila(payload) {
    const res = await fetch(`/AddressArea/Upazila/AutoComplete`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'datacontent': await getToken()
        },
    });
    const data = await res.json();

    if (!res.ok) {
        throw new Error(data.message || 'Could not fetch location data');
    }

    if (res.ok && data.IsError) {
        throw new Error('User defined errors');
    }

    const transformedData = [];

    data.Data.Data[0].forEach(upzila => {
        transformedData.push({
            id: upzila[1],
            name: upzila[0]
        });
    })

    return transformedData;
}

export async function getProductReviews(id) {
    const res = await fetch(`${BASE_URL}`);
    const data = await res.json();

    if (!res.ok) {
        throw new Error(data.message || 'Could not fetch product data');
    }

    if (res.ok && data.IsError) {
        throw new Error('User defined errors');
    }
}

// Deprecated
export async function getAddresses(id) {
    const res = await fetch(`${BASE_URL}/AddressArea/Address/Get`, {
        method: 'POST',
        body: JSON.stringify({
            PageNumber: 1,
            PageSize: 3,
            filter: [{
                field: "CustomerId",
                value: id,
                "Operation": 0
            }]
        }),
        headers: {
            'Content-Type': 'application/json',
            'datacontent': await getToken()
        },
    });
    const data = await res.json();

    if (!res.ok) {
        throw new Error(data.message || 'Could not fetch product data');
    }

    if (res.ok && data.IsError) {
        throw new Error('User defined errors');
    }

    const tranformedData = [];

    data.Data.Data.forEach(address => {
        tranformedData.push({
            ...address
        });
    })

    return tranformedData;
}

export async function getAppReviews() {
    const res = await fetch(`${BASE_URL}`);
    const data = await res.json();

    if (!res.ok) {
        throw new Error(data.message || 'Could not fetch product data');
    }

    if (res.ok && data.IsError) {
        throw new Error('User defined errors');
    }
}

export async function addAddress(addressData) {
    const response = await fetch(`${BASE_URL}/AddressArea/Address/Create`, {
        method: 'POST',
        body: JSON.stringify(addressData),
        headers: {
            'Content-Type': 'application/json',
            'datacontent': await getToken()
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not create quote.');
    }

    return null;
}

export async function updateUser(user) {
    const response = await fetch(`${BASE_URL}/CustomerArea/Customer/UpdateInfo`, {
        method: 'POST',
        body: JSON.stringify(user),
        headers: {
            'Content-Type': 'application/json',
            'datacontent': await getToken()
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not create quote.');
    }

    if (data.IsError) {
        throw new Error(data.message || 'Could not create quote.');
    }

    return data;
}

export async function getWishlistedProducts(id) {
    const response = await fetch(`${BASE_URL}/WishListArea/WishList/MyWishList/${id}`,{
        headers: {
            'content-type': 'application/json',
            'datacontent': await getToken()
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Failed to get wishlisted products');
    }

    if (data.IsError) {
        throw new Error(`${data.Msg || `Failed to load wishlisted data`} | ${data.Id}`);
    }

    return data.Data;
}

export async function addToWishlist(body, onSuccess, onFail) {
    const response = await fetch(`${BASE_URL}/WishListArea/WishList/Create`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'datacontent': await getToken()
        },
        body: JSON.stringify(body)
    });

    const data = await response.json();

    if (!response.ok) {
        onFail();
        throw new Error(data.message || 'Failed to get wishlisted products');
    }

    onSuccess && onSuccess();
}

export async function removeFromWishlist(body, onSuccess, onFail) {
    const response = await fetch(`${BASE_URL}/WishListArea/WishList/Remove`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'datacontent': await getToken()
        },
        body: JSON.stringify(body)
    });

    const data = await response.json();

    if (!response.ok) {
        onFail();
        throw new Error(data.message || 'Failed to get wishlisted products');
    }

    onSuccess && onSuccess();
}

export async function uploadPrescription({ image, description, activityId, callback }) {
    const formData = new FormData();
    formData.append('Img', image);
    formData.append('From', 'Upload Prescription');
    formData.append('ActivityId', activityId);
    formData.append('Description', description);

    const response = fetch(`${BASE_URL}/ProductOrderArea/Prescription/Add`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/binary',
            'datacontent': await getToken()
        },
        body: formData
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error('Failed upload prescription');
    }

    callback();

    return data;
}

export async function getPrescriptions(id) {
    const response = await fetch(`${BASE_URL}reate`,{
        headers: {
            'content-type': 'application/json',
            'datacontent': await getToken()
        },
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Failed to get wishlisted products');
    }

    if (data.IsError) {
        throw new Error(data.Msg || 'Failed to get wishlisted products');
    }

    return data;
}

export async function login(loginData, onSuccessed, onFailed){
    
    const response = await fetch(`${BASE_URL}/CustomerArea/AppCustomer/Login`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify(loginData)
    });

    const data = await response.json();

    if(!response.ok){
        throw new Error(data.message || 'Login failed');
    }

    if(data.IsError){
        throw new Error(`${data.Msg || 'Login failed (c)'}`);
    }

    onSuccessed(data);
    return data;
}

export async function postOrder(orderDto, onSuccessed = () => {}, onFailed = () => {}){
    const response = await fetch(`${BASE_URL}/ProductOrderArea/ProductOrder/Add`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'datacontent': await getToken()
        },
        body: JSON.stringify(orderDto)
    });

    const data = await response.json();

    if(!response.ok){
        onFailed(data);
        throw new Error(data.message || 'Login failed');
    }

    if(data.IsError){
        onFailed(data);
        throw new Error(`${data.Msg || 'Login failed'} ${data.Id}`);
    }

    onSuccessed(data.Data);
    return data.Data;
}

// replace getall with all before production
export async function getOrders(onSuccessed = () => {}, onFailed = () => {}){
    const response = await fetch(`${BASE_URL}/ProductOrderArea/ProductOrder/Get`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'datacontent': await getToken()
        },
    }); 

    const data = await response.json();

    if(!response.ok){
        onFailed(data);
        throw new Error(data.message || 'Login failed');
    }

    if(data.IsError){
        onFailed(data);
        throw new Error(`${data.Msg || 'Login failed'} ${data.Id}`);
    }

    onSuccessed(data.Data.Data);
    return data.Data.Data;
}

export async function getOrder(id, onSuccessed = () => {}, onFailed = () => {}){
    const response = await fetch(`${BASE_URL}/ProductOrderArea/ProductOrder/Details/${id}`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'datacontent': await getToken()
        },
    }); 

    const data = await response.json();

    if(!response.ok){
        onFailed(data);
        throw new Error(data.message || 'Login failed');
    }

    if(data.IsError){
        onFailed(data);
        throw new Error(`${data.Msg || 'Login failed'} ${data.Id}`);
    }

    onSuccessed(data.Data);
    return data.Data;
}
