import { Fragment, useContext, useEffect, useRef, useState } from "react";
import InputControl from "../../utilities/InputControl/InputControl";
import { IqraForm } from "../../../services/IqraService";
import AuthContext from "../../../store/auth-context";
import { useHistory } from "react-router";
import { login } from "../../../lib/api";
import LoadingText from "../../Suspenses/LoadingText";

const Login = (props) => {
  const authCtx = useContext(AuthContext);
  const [isFailed, setIsFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const formModel = {};
  const history = useHistory();
  const [formIsDirty, setFormIsDirty] = useState(false);

  const loginHandler = async () => {
    if(!formModel.IsValid){
      setFormIsDirty(true);
      return false;
    }

    setIsFailed(false);
    setIsLoading(true);

    try {
      await login(formModel, (data) => {
        setIsLoading(true);
        authCtx.onLogin(data);
        history.push(props.onLoginNavigate);
        props.onSuccess();
      });
    } catch (error) {
      setIsLoading(false);
      setIsFailed(true);
    }
  };

  const formRef = useRef();

  useEffect((param) => {
    IqraForm.Bind(formModel, formRef.current);
  });

  let message = null;
  if (isFailed) {
    message = (
      <p className="t-bold t-secondary t-center mb-12 t-12">
        Login failed, Phone or Password is wrong
      </p>
    );
  }

  if(!formModel.IsValid && formIsDirty){
    message = (
      <p className="t-bold t-secondary t-center mb-12 t-12">
        Phone number and password is required
      </p>
    );
  }

  if (isLoading) {
     message =<LoadingText className='flex justify-center mb-12'/>;
  }

  return (
    <Fragment>
      <div ref={formRef}>
        <div className="mb-16">
          <div className="form__control mb-8">
            <InputControl
              name={"phone"}
              label={"Phone Number"}
              error={undefined}
              data-binding={"UserName"}
              required
            />
          </div>
          <div className="form__control">
            <InputControl
              name={"password"}
              label={"Password"}
              error={undefined}
              data-binding={"Password"}
              required
              type="password"
            />
          </div>
        </div>

        {message}

        <div className="flex justify-between align-end">
          <div className="">
            <p className="t-bold t-14 mb-8">
              Don't have account?
              <span
                className="t-secondary t-hover-underline pointer ml-4"
                onClick={props.onRegistrationClickHandler}
              >
                REGISTER
              </span>
            </p>
            <p className="t-bold t-14">
              Forgot password?
              <span
                className="t-secondary t-hover-underline pointer ml-4"
                onClick={props.onResetPasswordClickHandler}
              >
                RECOVER
              </span>
            </p>
          </div>
          <div>
            <button
              type="button"
              onClick={loginHandler}
              className="brick fill primary round-corner"
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
