import SideDrawer from "../SideDrawer/SideDrawer";
import ham from "../../icons/e-hamburger-black.svg";
import { Fragment, useState } from "react";

const Sidebar = () => {
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);

  const showSideDrawer = () => {
    setDrawerIsVisible(true);
  };

  const hideSideDrawer = () => {
    setDrawerIsVisible(false);
  };
  return (
    <Fragment>
      <button className="nav-mid__logo--ham" onClick={showSideDrawer}>
        <img src={ham} alt="" className="w-max"/>
      </button>
      {drawerIsVisible && <SideDrawer onHide={hideSideDrawer} />}
    </Fragment>
  );
};

export default Sidebar;
