import NavLowLinks from './NavLowLinks/NavLowLinks';
import CategoryToggler from './CategoryToggler/CategoryToggler';

const LINKS = [
  {
    to: '/',
    text: 'Home',
  },
  {
    to: '/request',
    text: 'Request Order',
  },
  {
    to: '/products/offers/all',
    text: 'Special Offters'
  },
  {
    to: '/reviews',
    text: 'Reviews'
  },
  {
    to: '/about',
    text: 'about us',
  },
  {
    to: '/contact',
    text: 'contact us',
  },
];

const NavLow = () => {
  return (
    <section className='bg-nav-bottom' id='bottom-nav'>
      <nav className='nav-bottom'>
        <div className='nav-bottom__items'>
          <CategoryToggler />
          <NavLowLinks links={LINKS} />
        </div>
      </nav>
    </section>
  );
};

export default NavLow;
