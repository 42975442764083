import { useContext } from "react";
import { Fragment } from "react";
import SearchContext from "../../../store/search-context";
import SearchField from "./SearchField/SearchField";
import SearchResult from "./SearchResult/SearchResult";

const SearchBox = ({
  ResultTemplate,
  placeholderText,
  buttonSettings,
  items,
  isLoading,
  onSearch,
}) => {
  const searchCtx = useContext(SearchContext);

  const clickHandler = () => {
    searchCtx.hide();
  }

  return (
    <Fragment>
      <SearchField
        placeholderText={placeholderText}
        buttonSettings={buttonSettings}
        onChange={onSearch}
      />
      {searchCtx.isVisible && (
        <SearchResult
          items={items}
          isLoading={isLoading}
          ResultTemplate={ResultTemplate}
          onClick={clickHandler}
        />
      )}
    </Fragment>
  );
};

export default SearchBox;
