import { getCategoryDisplay } from "../../../services/AppService";
import CategoryRow from "./CategoryRow/CatelgoryRow";
import { useRef, useState } from "react";
import Paginators from "../../Paginators/Paginators";
import useGoTop from "../../../hooks/use-go-top";

const CategoryDisplay = () => {
  const [rows, setRows] = useState(getCategoryDisplay(1));
  const elRef = useRef();
  const [goTop] = useGoTop();

  const pageChangeHandler = (n) => {
    const list = getCategoryDisplay(n);
    setRows(list);
    goTop({element: elRef.current, topOffset: -150});
  };

  return (
    <section ref={elRef}>
      {rows.items.map((cd, i) => (
        <CategoryRow categoryWithProduct={cd} key={cd[0]} i={i} />
      ))}
      <div className="flex justify-end container my-16">
        <Paginators
          items={rows.length}
          pageItems={5}
          onPageChange={pageChangeHandler}
          startPage={1}
        />
      </div>
    </section>
  );
};

export default CategoryDisplay;
