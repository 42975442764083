import playstore from '../../../../images/google-play-button.png.webp';

export const FooterAndroidPart = () => {
    return (
        <a className='pointer kill-anchore' target={'_blank'} href='https://play.google.com/store/apps/details?id=com.iqrasys.finepharmaltd' rel="noreferrer">
        <h3 className='t-uppercase mb-8 t-bold'>Download Our Android App</h3>
        <div className="download-links">
            <img src={playstore} alt="" />
        </div>
      </a>  
    )
}
