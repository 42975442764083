import React, { Fragment } from  'react';

class AppErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
      }
    
      static getDerivedStateFromError(error) {
        return { hasError: true };
      }
    
      componentDidCatch(error, errorInfo) {
      }
    
      render() {
        if (this.state.hasError) {
          return <Fragment>
            <h1 className='t-center t-secondary m-20 p-16'>Something went wrong.</h1>
            <h2 className='t-primary t-center t-bold t-16'>Refresh the Browser</h2>  
          </Fragment>;
        }
    
        return this.props.children; 
      }
}

export default AppErrorBoundary;