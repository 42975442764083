const PerksTemplate = ({ item }) => {
  return (
    <div className="perks__slider__perk pointer hover-bg-light" onClick={item.fun}>
      <div className="perks__slider__perk--icon">
        <img src={item.logo} alt="" />
      </div>
      <div className="perks__slider__perk--text">
        <h3 className="block t-bold t-14 mb-8 perk__title">{item.title}</h3>
        <p>{item.description}</p>
      </div>
    </div>
  );
};

export default PerksTemplate;
