import React, { Suspense, useCallback, useContext, useEffect, useRef } from 'react';
import { Route, Switch } from 'react-router-dom';
import LoadingSpinner from './components/utilities/LoadingSpinner/LoadingSpinner';
import { useLocation } from 'react-router';

import MainHeader from './components/MainHeaders/MainHeader';
import Home from './pages/Home';
import Footer from './components-t/Footer/Footer';
import Cart from './components/Cart/Cart';

import './App.css';
import AuthContext from './store/auth-context';
import Unauthenticate from './components/Suspenses/Unauthenticate';
import { Activity } from './services/IqraService';
import { http } from './services/httpService';
import { endpoint } from './lib/endpoints';
import AppContext from './store/app-context';
import AppErrorBoundary from './components/AppErrorBoundary/AppErrorBoundary';

const Offers = React.lazy(() => import('./pages/Offers'));
const ProductsByCategory = React.lazy(() => import('./pages/ProductsByCategory'));
const Wishlist = React.lazy(() => import('./pages/Wishlist'));
const Product = React.lazy(() => import('./pages/Product'));
const RequestProducts = React.lazy(() => import('./components/RequestProducts/ReuestProducts'));
const Contact = React.lazy(() => import('./pages/Contact'));
const About = React.lazy(() => import('./pages/About'));
const Checkout = React.lazy(() => import('./pages/Checkout'));
const Profile = React.lazy(() => import('./pages/Profile'));
const Notifications = React.lazy(() => import('./pages/Notifications'));
const Reviews = React.lazy(() => import('./pages/Reviews'));
const TermsNConditions = React.lazy(() => import('./components/TermsNConditions/TermsNConditions'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const ReturnPolicy = React.lazy(() => import('./components/ReturnPolicy/ReturnPolicy'));
const PrivacyPolicy = React.lazy(() => import('./components/PrivacyPolicy/PrivacyPolicy'));
const SearchProducts = React.lazy(() => import('./components/SearchedProducts/SearchProducts'));
const HowToOrder = React.lazy(() => import('./components/HowToOrder/HowToOrder'));

function App() {
  const { wishlist, userInfo } = useContext(AppContext);
  const authCtx = useContext(AuthContext);
  const location = useLocation();
  const headerRef = useRef();
  const mainRef = useRef();

  useEffect(() => {
    const headerHeight = headerRef.current?.clientHeight;
    mainRef.current.style.paddingTop = `${headerHeight}px`;
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location]);

  const { storeProducts, removeProducts } = wishlist;
  const { update, destroy } = userInfo;

  const getUserInfo = useCallback(() => {
    http.get({
      url: endpoint.getUserInfo,
      successed: (data) => {
        storeProducts(data.wishlist);
        update(data.notificationCount, data.pendingCash, data.fineCash);
      },
      map: (data) => {
        const wishlist = {};
        let count = 0;
        data.Data.WishListId.forEach(item => { wishlist[item.Id] = item.Id; count++ });

        return {
          wishlist: { ...wishlist, count },
          notificationCount: data.Data.NotificationCount.NotificationCount,
          pendingCash: data.Data.Customer.Pending,
          fineCash: data.Data.Customer.Cashback
        };
      }
    })
  }, [storeProducts, update]);


  const { getUserAsync } = authCtx;
  useEffect(() => {
    const waiter = async () => {
      const user = await getUserAsync();

      if (user) {
        getUserInfo();
      } else {
        removeProducts();
        destroy();
      }
    }

    waiter();
  }, [getUserInfo, getUserAsync, removeProducts, destroy]);

  useEffect(() => {
    Activity.Set();
  }, [location]);

  const spinner = <div className='page'>
    <LoadingSpinner />
  </div>

  return (
    <AppErrorBoundary>
      <div className='app'>
        <MainHeader ref={headerRef} />
        <main ref={mainRef}>
          <AppErrorBoundary>
            <Switch>
              <Route path='/' exact>
                <Home />
              </Route>
              <Route path='/Product/details/:productId' >
                <Suspense fallback={spinner}>
                  <Product />
                </Suspense>
              </Route>
              <Route path='/Request'>
                <Suspense fallback={spinner}>
                  <RequestProducts />
                </Suspense>
              </Route>
              <Route path='/Contact'>
                <Suspense fallback={spinner}>
                  <Contact />
                </Suspense>
              </Route>
              <Route path='/About'>
                <Suspense fallback={spinner}>
                  <About />
                </Suspense>
              </Route>
              <Route path='/reviews' >
                <Suspense fallback={spinner}>
                  <Reviews />
                </Suspense>
              </Route>
              <Route path='/Checkout'>
                <Suspense fallback={spinner}>
                  {authCtx.isLoggedIn && <Checkout />}
                  {!authCtx.isLoggedIn && <Unauthenticate />}
                </Suspense>
              </Route>
              <Route path='/Profile'>
                <Suspense fallback={spinner}>
                  {authCtx.isLoggedIn && <Profile />}
                  {!authCtx.isLoggedIn && <Unauthenticate />}
                </Suspense>
              </Route>
              <Route path='/terms-and-conditions'>
                <Suspense fallback={spinner}>
                  <TermsNConditions />
                </Suspense>
              </Route>
              <Route path='/return-policy'>
                <Suspense fallback={spinner}>
                  <ReturnPolicy />
                </Suspense>
              </Route>
              <Route path='/privacy-policy'>
                <Suspense fallback={spinner}>
                  <PrivacyPolicy />
                </Suspense>
              </Route>
              <Route path='/notifications'>
                <Suspense fallback={spinner}>
                  <Notifications />
                </Suspense>
              </Route>
              <Route path='/products/wishlist' exact>
                <Suspense fallback={spinner}>
                  {authCtx.isLoggedIn && <Wishlist />}
                  {!authCtx.isLoggedIn && <Unauthenticate />}
                </Suspense>
              </Route>
              <Route path='/products/category/:id'>
                <Suspense fallback={spinner}>
                  <ProductsByCategory />
                </Suspense>
              </Route>
              <Route path='/products/Search'>
                <Suspense fallback={spinner}>
                  <SearchProducts />
                </Suspense>
              </Route>
              <Route path='/products/offers'>
                <Suspense fallback={spinner}>
                  <Offers />
                </Suspense>
              </Route>
              <Route path='/How-It-Works'>
                <Suspense fallback={spinner}>
                  <HowToOrder />
                </Suspense>
              </Route>
              <Route path='/test'>
                <h1>Test</h1>
              </Route>
              <Route path='*'>
                <Suspense fallback={spinner}>
                  <NotFound />
                </Suspense>
              </Route>
            </Switch>
          </AppErrorBoundary>
        </main>
        <Footer />
        <Cart />
      </div>
    </AppErrorBoundary>
  );
}

export default App;
