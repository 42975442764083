import OpenCart from "./OpenCart/OpenCart";
import CloseCart from "./CloseCart/CloseCart";
import { useState } from "react";

const Cart = () => {
  const [isCartOpened, setIsCartOpened] = useState(false);

  const toggleCart = () => {
    setIsCartOpened((prevState) => !prevState);
  };

  return (
    <>
      <div className="cart">
        {isCartOpened && <OpenCart closeCart={toggleCart} />}
        {!isCartOpened && <CloseCart openCart={toggleCart} />}
      </div>
    </>
  );
};

export default Cart;
