import { Fragment, useEffect } from "react";
import ReactDOM from "react-dom";
import "./Popup.css";

const Popup = ({ isInitHidden, title, BodyComponent, onClose, children, className }) => {
  children = children ?? <p>No Body component supplied</p>;
  const close = () => {
    onClose();
  };

  const popUpEl = document.getElementById("pop-up");

  useEffect(() => {
    document.querySelector("body").style.position = "fixed";

    return () => {
      document.querySelector("body").style.position = "unset";
    };
  }, []);

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <div className="overlay__popup show">
          <div className={`popup ${className}`}>
            <div className="popup__title">
              <h2>{title}</h2>
              <div onClick={close}>&#10006;</div>
            </div>
            <div className="popup__body">
              {BodyComponent ? (
                <BodyComponent />
              ) : (
                children
              )}
            </div>
          </div>
        </div>,
        popUpEl
      )}
    </Fragment>
  );
};

export default Popup;
