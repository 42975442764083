import { Link } from "react-router-dom";
import { getCategories } from "../../services/AppService";

const SideDrawer = ({ onHide }) => {
  const categories = getCategories();
  const links = [
    {
      to: "/",
      text: "Home",
    },
    {
      to: "/request",
      text: "Request order",
    },
    {
      to: "/products/offers/all",
      text: "Special Offters",
    },
    {
      to: "/reviews",
      text: "Reviews",
    },
    {
      to: "/about",
      text: "about us",
    },
    {
      to: "/contact",
      text: "contact us",
    },
  ];

  return (
    <div id="js--drawer" className="drawer">
      <div className="drawer__categories">
        <header className="drawer__categories__category--header">
          <h2>Categories</h2>
          <div
            className=""
            onClick={onHide}
          >
            &#10006;
          </div>
        </header>
        {categories.map((category) => (
          <div
            className="drawer__categories__category"
            key={category[0] + category[1]}
          >
            <Link
              to={`/products/category/${category[0]}`}
              onClick={onHide}
              className="drawer__categories__category"
            >
              {category[1]}
            </Link>
          </div>
        ))}
      </div>

      <div className="drawer__links">
        {links.map((link) => (
          <div className="drawer__links__link">
            <Link to={link.to} onClick={onHide} className="drawer__links--link">
              {link.text}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SideDrawer;
