import freeDelivery from "../../../icons/free-delivery.svg";
import fSupport from "../../../icons/f-support.svg";
import creditCard from "../../../icons/credit-card.svg";
import pilot from "../../../icons/pilot.svg";

export const TopFooter = () => {
  return (
    <div className="footer__features">
      <div className="footer__features--feature">
        <div className="footer__features--feature__icon">
          <img src={freeDelivery} alt="" />
        </div>
        <div className="footer__features--feature__text">
          <h3>Free Shipping.</h3>
          <p>Order 999TK inside Dhaka.</p>
        </div>
      </div>
      <div className="footer__features--feature">
        <div className="footer__features--feature__icon">
          <img src={fSupport} alt="" />
        </div>
        <div className="footer__features--feature__text">
          <h3>Quick Support.</h3>
          <p>We are waiting for your call.</p>
        </div>
      </div>
      <div className="footer__features--feature">
        <div className="footer__features--feature__icon">
          <img src={creditCard} alt="" />
        </div>
        <div className="footer__features--feature__text">
          <h3>Online Payment.</h3>
          <p>Any kinds of online payment.</p>
        </div>
      </div>
      <div className="footer__features--feature">
        <div className="footer__features--feature__icon">
          <img src={pilot} alt="" />
        </div>
        <div className="footer__features--feature__text">
          <h3>Fast Delivery.</h3>
          <p>Same day delivery.</p>
        </div>
      </div>
    </div>
  );
};
