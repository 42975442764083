import { useEffect } from "react";
import { Link } from "react-router-dom";
import { getCategories } from "../../../../services/AppService";
import "./Categories.css";

const Categories = () => {
  const categories = getCategories();

  useEffect(() => {
    document.querySelector("body").style.overflow = "hidden";

    return () => {
      document.querySelector("body").style.overflow = "unset";
    };
  }, []);

  return (
    <div className="categories bg-primary pt-8">
      {categories.map((category) => (
        <Link
          className="category t-bold t-16 flex align-center py-4 px-16 mb-12 block kill-anchore t-uppercase"
          key={category[0]}
          to={`/products/category/${category[0]}`}
        >
          {category[1]}
        </Link>
      ))}
    </div>
  );
};

export default Categories;
