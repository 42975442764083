import AdressFooter from "./FooterContent/AdressFooter";
import { FooterAndroidPart } from "./FooterContent/FooterContentSocial/FooterAndroidPart";
import { SocialContent } from "./FooterContent/FooterContentSocial/SocialContent";
import { FooterLinks } from "./FooterContent/FooterLinks";
import { FooterPayments } from "./FooterContent/FooterPayments";
import { TopFooter } from "./FooterContent/TopFooter";

const Footer = () => {
  return (
    <section className="bg-footer">
      <div className="footer">
        <TopFooter></TopFooter>
        <div className="footer__content">
          <AdressFooter></AdressFooter>
          <FooterLinks></FooterLinks>

          <FooterPayments></FooterPayments>
          <div className="footer__content--socials">
            <SocialContent></SocialContent>
            <FooterAndroidPart></FooterAndroidPart>
          </div>
        </div>
        <div className="flex justify-center py-12">
          <p className="t-center">
            Pharmarcy Software and Website Developed By <br/>
            <a
              rel="noreferrer"
              className="t-hover-underline"
              target="_blank"
              href="https://iqrasys.com"
              style={{ color: "#F37805" }}
            >
              <strong >
                Iqrasys solution Limited
              </strong>
            </a>
            {' '}
            (01778-772327)
          </p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
