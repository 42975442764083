import { useCallback, useReducer } from "react";
import { calcAmount, calcMRP } from "../helpers/utilies";
import CartContext from "./cart-context";

const storeCartState = (state) => {
  localStorage.setItem("CARTV1.2", JSON.stringify(state));
};

const defaultCartState = () => {
  localStorage.removeItem('CART');
  const storedCart = localStorage.getItem("CARTV1.2");
  if (storedCart) {
    return JSON.parse(storedCart);
  }

  return { products: [], amount: 0, discountedAmount: 0 };
};

const cartReducer = (state, action) => {
  if (action.type === "ADD_PRODUCT") {
    let updatedProducts = [...state.products];
    let updatedDiscountedAmount = state.discountedAmount;
    let updatedAmount = state.amount;

    const existingProductIndex = updatedProducts.findIndex(
      (product) => product.id === action.product.id
    );
    const existingProduct = updatedProducts[existingProductIndex];

    if (existingProduct) {
      if (action.product.quantity === existingProduct.quantity) {
        alert("Product already exist in your cart");
      } else {
        const newProduct = {
          ...action.product,
          MRP: action.product.price - action.product.discount,
          discountedAmount: calcAmount(
            action.product.price,
            action.product.discount,
            action.product.quantity
          ),
          amount: calcAmount(action.product.price, 0, action.product.quantity),
        };

        updatedProducts[existingProductIndex] = newProduct;
        updatedAmount = updatedProducts.reduce(
          (total, product) => total + product.amount,
          0
        );
        updatedDiscountedAmount = updatedProducts.reduce(
          (total, product) => total + product.discountedAmount,
          0
        );
      }
    } else {
      const newProduct = {
        ...action.product,
        MRP: action.product.price - action.product.discount,
        discountedAmount: calcAmount(
          action.product.price,
          action.product.discount,
          action.product.quantity
        ),
        amount: calcAmount(action.product.price, 0, action.product.quantity),
      };

      updatedProducts = updatedProducts.concat([newProduct]);
      updatedDiscountedAmount =
        state.discountedAmount + newProduct.discountedAmount;
      updatedAmount = state.amount + newProduct.amount;
    }

    const newState = {
      products: updatedProducts,
      discountedAmount: updatedDiscountedAmount,
      amount: updatedAmount,
    };

    storeCartState(newState);

    return newState;
  }

  if (action.type === "REMOVE_PRODUCT") {
    let updatedProducts = [...state.products];
    let updatedDiscountedAmount = state.discountedAmount;
    let updatedAmount = state.amount;

    const existingProductIndex = updatedProducts.findIndex(
      (product) => product.id === action.product.id
    );
    const existingProduct = updatedProducts[existingProductIndex];

    if (existingProduct) {
      updatedProducts = updatedProducts.filter(
        (product) => product.id !== existingProduct.id
      );
      updatedDiscountedAmount -= existingProduct.discountedAmount;
      updatedAmount -= existingProduct.amount;
    } else {
      alert("Product doesn't exist in the shopping cart");
    }

    const newState = {
      products: updatedProducts,
      discountedAmount: updatedDiscountedAmount,
      amount: updatedAmount,
    };

    storeCartState(newState);

    return newState;
  }

  if (action.type === "UPDATE_PRODUCT_QUANTITY") {
    let updatedProducts = [...state.products];
    let updatedDiscountedAmount = state.discountedAmount;
    let updatedAmount = state.amount;

    const existingProductIndex = updatedProducts.findIndex(
      (product) => product.id === action.product.id
    );
    const existingProduct = updatedProducts[existingProductIndex];

    if (existingProduct) {
      const newProduct = {
        ...action.product,
        MRP: calcMRP(action.product.price, action.product.discount),
        discountedAmount: calcAmount(
          action.product.price,
          action.product.discount,
          action.product.quantity
        ),
        amount: calcAmount(action.product.price, 0, action.product.quantity),
      };

      updatedProducts[existingProductIndex] = newProduct;
      updatedDiscountedAmount = updatedProducts.reduce(
        (total, product) => total + product.discountedAmount,
        0
      );
      updatedAmount = updatedProducts.reduce(
        (total, product) => total + product.amount,
        0
      );
    }

    const newState = {
      products: updatedProducts,
      discountedAmount: updatedDiscountedAmount,
      amount: updatedAmount,
    };

    storeCartState(newState);

    return newState;
  }

  if (action.type === "CLEAR_CART") {
    const newState = {
      products: [],
      discountedAmount: 0,
      amount: 0,
    };

    storeCartState(newState);

    return newState;
  }

  if(action.type === 'UPDATE_CART_PRODUCT_INFO'){
    const updatedProducts = state.products.map(p => {
      const updatedProduct = action.products.find(ap => ap.Id === p.id);
      if(!updatedProduct) return p;

      return {
        ...p,
        price: updatedProduct.UnitSalePrice,
        isPrescriptionRequired: updatedProduct.IsPrescriptionRequired,
        discount: updatedProduct.Discount,
        amount: p.quantity * (updatedProduct.UnitSalePrice),
        MRP: updatedProduct.UnitSalePrice - updatedProduct.Discount,
        discountedAmount: p.quantity * (updatedProduct.UnitSalePrice - updatedProduct.Discount)
      }
    });

    return {
      ...state,
      products: updatedProducts,
      discountedAmount: updatedProducts.reduce(
        (total, product) => total + product.discountedAmount,
        0
      ),
      amount: updatedProducts.reduce(
        (total, product) => total + product.amount,
        0
      )
    }
  }

  return defaultCartState;
};

const CartContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, defaultCartState());

  const addProductHandler = (product) => {
    dispatch({ type: "ADD_PRODUCT", product });
  };

  const removeProductHandler = (product) => {
    dispatch({ type: "REMOVE_PRODUCT", product });
  };

  const updateProductQuantityHandler = (product) => {
    dispatch({ type: "UPDATE_PRODUCT_QUANTITY", product });
  };

  const clearCartHandler = () => {
    dispatch({ type: "CLEAR_CART" });
  };

  const isExist = (id) => {
    return state.products.find(p => p.id === id) ? true : false;
  } 

  const getCartProduct = useCallback((id) => {
    return state.products.find(p => p.id === id);
  }, [state.products]);

  const updateProducts = useCallback((products)=>{
    dispatch({ type: "UPDATE_CART_PRODUCT_INFO", products });
  }, []);
    
  

  const cartContext = {
    products: state.products,
    amount: state.amount,
    discountedAmount: state.discountedAmount,
    addProduct: addProductHandler,
    removeProduct: removeProductHandler,
    updateProductQuantity: updateProductQuantityHandler,
    clearCart: clearCartHandler,
    isExist,
    getCartProduct,
    updateProducts
  };

  return (
    <CartContext.Provider value={cartContext}>{children}</CartContext.Provider>
  );
};

export default CartContextProvider;
