import { getDisplayBanner } from "../../services/AppService";
import Slider from "../utilities/Slider/Slider";
import BannerTemplate from "./BannerTemplate/BannerTemplate";

const Banner = () => {
  let banners = getDisplayBanner();

  const width = window.innerWidth;

  let selectedBanners = banners.filter((b) => b[2] === "large");

  if (764 > width) {
    const medium = banners.filter((b) => b[2] === "medium");

    if (medium.length !== 0) {
      selectedBanners = medium;
    }
  } else if (width > 764) {
    selectedBanners = selectedBanners.length === 0 ? banners : selectedBanners;
  } else if (400 > width) {
    const small = banners.filter((b) => b[2] === "small");

    if (small.length === 0) {
      const medium = banners.filter((b) => b[2] === "medium");

      if (medium.length !== 0) {
        selectedBanners = medium;
      }
    } else {
      selectedBanners = small;
    }
  }

  const options = {
    rewind: true,
    type: "loop",
    autoplay: true,
    rewindSpeed: 1000,
    speed: 500,
  };

  return (
    <section className="bg-slider">
      <Slider
        items={selectedBanners}
        Template={BannerTemplate}
        options={options}
      />
    </section>
  );
};

export default Banner;
