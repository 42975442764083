import SearchBox from "../../../utilities/SearchBox/SearchBox";
import "./NavMidSearchBar.css";
import icons from "../../../../icons/icons";
import SearchEdProducts from "../SearchedProducts/SearchedProducts";
import { useRef, useContext, useState, useEffect } from "react";
import { useOutsideAlerter } from "../../../../hooks/useOutsideClickHandler";
import SearchContext from "../../../../store/search-context";
import AppContext from "../../../../store/app-context";
import { tranformQuery } from "../../../../helpers/utilies";
import { endpoint } from "../../../../lib/endpoints";
import { getTokenSync } from "../../../../helpers/token";
import { baseUrl } from "../../../../services/ImgService";

const NavMidSearchBar = () => {
  const appCtx = useContext(AppContext);
  const [inputTimeout, setInputTimeout] = useState(null);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => () => clearTimeout(inputTimeout), [inputTimeout]);

  const controller = useRef();

  const searchHandler = (text) => {
    if (controller.current) {
      controller.current.abort();
    }

    if (text.length === 0) {
      setItems([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    if (inputTimeout) clearTimeout(inputTimeout);

    appCtx.searchQuery.storeSearchQuery(text);

    setInputTimeout(
      setTimeout(() => {
        controller.current = new AbortController();
        const signal = controller.current.signal;

        const query = tranformQuery(text);
        const token = getTokenSync();
        const tokenHeader = {};

        if (token) {
          tokenHeader["datacontent"] = token;
        }

        fetch(baseUrl + "/" + endpoint.searchProducts, {
          signal,
          method: "POST",
          headers: {
            "content-type": "application/json",
            ...tokenHeader,
          },
          body: JSON.stringify({
            Query: query,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.IsError) {
              throw new Error(`${data.Msg || "Login failed"}`);
            }

            setItems(data.Data);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }, 50)
    );
  };

  const rearchRef = useRef(null);
  const searchCtx = useContext(SearchContext);
  useOutsideAlerter(rearchRef, () => {
    searchCtx.hide();
  });

  return (
    <div className="nav-mid__search" ref={rearchRef}>
      <SearchBox
        ResultTemplate={SearchEdProducts}
        placeholderText={"Search by trade or generic name"}
        items={items}
        isLoading={isLoading}
        onSearch={searchHandler}
        buttonSettings={{
          icon: icons.search,
          isActive: true,
          options: {
            style: "fill",
            type: "primary",
            size: "big",
          },
        }}
      />
    </div>
  );
};

export default NavMidSearchBar;
