import { getNotices } from "../../../services/AppService";
import { notices as fallbackNotices } from "../../../services/data-service";
import { useRef } from 'react';

const Notice = () => {
  const marqueeRef = useRef();

  let notices = getNotices();
  if (!notices.length) {
    notices = fallbackNotices;
  }

  const mouseOverHandler = () => {
    marqueeRef.current.stop();
  };

  const mouseOutHandler = (e) => {
    marqueeRef.current.start();
  };

  return (
    <section className="bg-primary mt-16">
      {/* eslint-disable-next-line jsx-a11y/no-distracting-elements */}
      <marquee scrollamount="5" ref={marqueeRef}>
        <div className="flex justify-start">
          {notices.map((notice, i) => (
            <p
              key={i}
              className="t-bold t-18 p-16 t-white t-nowrap"
              onMouseOver={mouseOverHandler}
              onMouseOut={mouseOutHandler}
            >
              <span>&#10022; &nbsp;</span>
              <span>{notice}</span>
            </p>
          ))}
        </div>
      </marquee>
    </section>
  );
};

export default Notice;
