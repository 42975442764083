import { Fragment, useCallback, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { requestOTP } from "../../../helpers/utilies";
import { endpoint } from "../../../lib/endpoints";
import { http } from "../../../services/httpService";
import AuthContext from "../../../store/auth-context";
import LoadingText from "../../Suspenses/LoadingText";
import Alert from "../../utilities/Alert/Alert";
import InputControl from "../../utilities/InputControl/InputControl";

const VerifyOTP = ({
  OTPModel,
  phone,
  password,
  onSuccess,
  onLoginNavigate,
  verifyFor = requestOTP.registration,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [OTP, setOTP] = useState("");
  const [alert, setAlert] = useState(undefined);
  const [OTP_IsValid, setOTP_IsValid] = useState(true);
  const [OTP_res, setOTP_res] = useState(OTPModel);
  const history = useHistory();
  const {onLogin} = useContext(AuthContext);


  const submitHandler = () => {
    if (OTP.length === 0) {
      setOTP_IsValid(false);
      return false;
    }

    verifyOTP(OTP, OTP_res.Id, phone, password, verifyFor);
  };

  const focusHandler = () => {
    setOTP_IsValid(true);
  };

  const resendHandler = () => {
    getOTP(phone, verifyFor);
  };

  const getOTP = useCallback((phone, verifyFor) => {
    http.post({
      url:
        verifyFor === requestOTP.registration
          ? endpoint.getOTP
          : endpoint.getResetPasswordOTP,
      payload: {
        Phone: phone,
        ActivityId: window.ActivityId,
      },
      before: () => {
        setIsLoading(true);
      },
      successed: (data) => {
        setOTP_res(data);
        setAlert({
          type: "positive",
          text: "New OTP send to your phone number",
        });
      },
      failed: (data, message) => {
        setAlert({ type: "warn", text: message });
      },
      always: () => {
        setIsLoading(false);
      },
      map: (data) => {
        return data;
      },
    });
  }, []);

  const login = useCallback(
    (phone, password) => {
      http.post({
        url: endpoint.login,
        payload: {
          UserName: phone,
          Password: password,
        },
        before: () => {
          setIsLoading(true);
        },
        successed: (data) => {
          onLogin(data);
          history.push(onLoginNavigate);
          onSuccess();
        },
        failed: (data, message) => {
          setAlert({ type: "warn", text: message });
        },
        always: () => {
          setIsLoading(false);
        },
        map: (data) => {
          return data;
        }
      });
    },
    [onSuccess, onLoginNavigate, history, onLogin]
  );

  const updatePassword = useCallback(
    (phone, password, OTP_id) => {
      http.post({
        url: endpoint.updatePassword,
        payload: {
          Phone: phone,
          Password: password,
          OTPId: OTP_id,
          ActivityId: window.ActivityId,
        },
        before: () => {
          setIsLoading(true);
        },
        successed: (data) => {
          login(phone, password);
        },
        failed: (data, message) => {
          setAlert({ type: "warn", text: message });
        },
        always: () => {
          setIsLoading(false);
        },
      });
    },
    [login]
  );

  const registerUser = useCallback(
    (phone, password, OTP_id) => {
      http.post({
        url: endpoint.registerUser,
        payload: {
          Name: "",
          Phone: phone,
          Password: password,
          OTPId: OTP_id,
          ActivityId: window.ActivityId,
        },
        before: () => {
          setIsLoading(true);
        },
        successed: (data) => {
          login(phone, password);
        },
        failed: (data, message) => {
          setAlert({ type: "warn", text: message });
        },
        always: () => {
          setIsLoading(false);
        },
      });
    },
    [login]
  );

  const verifyOTP = useCallback(
    (OTP, id, phone, password, verifyFor) => {
      http.post({
        url: endpoint.verifyOTP,
        payload: {
          Code: OTP,
          Id: id,
          ActivityId: window.ActivityId,
        },
        before: () => {
          setIsLoading(true);
        },
        successed: (data) => {
          if (verifyFor === requestOTP.registration)
            registerUser(phone, password, id);
          if (verifyFor === requestOTP.resetPassword)
            updatePassword(phone, password, id);
        },
        failed: (data, message) => {
          setAlert({ type: "warn", text: message });
        },
        always: () => {
          setIsLoading(false);
        },
        map: (data) => {
          return data;
        },
      });
    },
    [registerUser, updatePassword]
  );

  const alertCloseHandler = () => {
    setAlert(undefined);
  };

  const OTPChangeHandler = ({ target }) => {
    setOTP(target.value);
  };

  return (
    <Fragment>
      <div>
        <div className="mb-12">
          <div className="form__control mb-4">
            <InputControl
              name={"otp"}
              label={"Please Enter Your OTP Code"}
              error={!OTP_IsValid && "OTP can't be empty!"}
              value={OTP}
              onChange={OTPChangeHandler}
              onFocus={focusHandler}
            />
          </div>
        </div>
        <div style={{ minHeight: 12 }}>
          {isLoading && <LoadingText className="flex justify-center mb-8" />}
        </div>
        <div className="flex justify-between align-end">
          <div className="">
            <p className="t-bold t-14 mb-4">
              Didn't receive the OTP?
              <span
                className="t-secondary t-hover-underline pointer ml-4"
                onClick={resendHandler}
              >
                RESEND
              </span>
            </p>
          </div>
          <div>
            <button
              type="button"
              onClick={submitHandler}
              className="brick fill primary round-corner"
              disabled={isLoading}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      {alert && (
        <Alert
          type={alert.type}
          text={alert.text}
          onClose={alertCloseHandler}
        ></Alert>
      )}
    </Fragment>
  );
};

export default VerifyOTP;
