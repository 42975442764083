// * 1.
const postProductReview = `ReviewArea/Comment/Create`;
// * 2.
const getProductReviews = `ReviewArea/Comment/ByProduct`;
// * 3.
const postContactForm = `ContactArea/Contact/Add`;
// * 4.
const getSpecialProducts = `ProductArea/SpecialProduct/Get`;
// * 5.
const postCouponCode = `PromotionalArea/Coupon/Check`;
// * 6.
const postAppReview = `ReviewArea/AppReview/Create`;
// * 7.
const getAppReviews = `ReviewArea/AppReview/Get`;
// * 8.
const postProfilePicture = `CustomerArea/Customer/AddPicture`;
// * 9.
const updateProfile = `CustomerArea/Customer/UpdateInfo`;
// * 10.
const getPrescriptions = `ProductOrderArea/Prescription/MyPrescriptions/`;
// * 11.
const postPrescription = `ProductOrderArea/Prescription/Add`;
// * 12.
const getOTP = `MessagingArea/OtpMessage/WebRegister`;
// * 13.
const verifyOTP = `MessagingArea/OtpMessage/Check`;
// * 14.
const registerUser = `CustomerArea/Customer/Add`;
// * 15.
const getResetPasswordOTP = `MessagingArea/OtpMessage/WebUpdatePassword`;
// * 16.
const updatePassword = `CustomerArea/Customer/UpdatePassword`;
// * 17.
const getPrescriptionByOrderId = `ProductOrderArea/ProductOrder/Prescriptions/`;
// * 18.
const getOffers = `OfferArea/DiscountOffer/List`;
// * 19.
const getOffersInOrder = `OfferArea/DiscountOffer/Order`;
// * 20.
const getWishlistId = `WishListArea/WishList/MyWishListId/`;
// * 21.
const addToWishlist = `WishListArea/WishList/Create`;
// * 22.
const removeFromWishlist = `WishListArea/WishList/Remove`;
// * 23.
const logout = `CustomerArea/AppCustomer/LogOff`;
// * 24.
const postComplain = `CommonArea/Complain/Add`;
// * 25.
const attachPrescriptions = `ProductOrderArea/ProductOrder/AddPrescription`;
// * 26.
const postRequsetOrder = `RequestOrderArea/RequestOrder/Create`;
// * 27.
const removeOrderPrescription = `ProductOrderArea/ProductOrder/RemoveFile/`;
// * 28.
const removePrescription = `ProductOrderArea/Prescription/Remove/`;
// * 29.
const removeRequestPrescription = `RequestOrderArea/RequestOrder/RemoveFile`;
// * 30.
const getRequestedOrder = `RequestOrderArea/RequestOrder/Get`;
// * 31.
const getRequestOrderPrescription = `RequestOrderArea/RequestOrder/Prescriptions/`;
// * 32.
const getProductsByCategory = `AppDataArea/AppCategoryProduct/Get`;
// * 33.
const getRelatedProducts = `ProductArea/Product/GetRelated/`;
// * 34.
const getRequestOrderItems = `RequestOrderArea/RequestOrder/Items/`;
// * 35.
const getUserInfo = `CustomerArea/Customer/GetInfo`;
// * 36.
const searchProducts = `ProductArea/Product/Search`;
// * 37.
const getProduct = `ProductArea/ProductContent/ByProduct/`;
// * 38.
const getNotifications = `NoticeArea/Notification/Get`;
// * 39.
const markNotificationAsSeen = `NoticeArea/Notification/Seen?activityId=`;
// * 40.
const getAddresses = `AddressArea/Address/Get`;
// * 41.
const saveAddress = `AddressArea/Address/Create`;
// * 42.
const login = `CustomerArea/AppCustomer/Login`;
// * 43.
const getAllSearchedProduct = `ProductArea/Product/Searches`;
// * 44.
const getCurrentInfo = `ProductArea/Product/GetCurrentInfo`;
// * 45.
const getOrder = `ProductOrderArea/ProductOrder/Details/`;
// * 46.
const registerWithoutOTP = `CustomerArea/Customer/AddWithOutOTP`;


export const endpoint = {
    postProductReview,
    getProductReviews,
    postContactForm,
    getSpecialProducts,
    postCouponCode,
    postAppReview,
    getAppReviews,
    postProfilePicture,
    updateProfile,
    getPrescriptions,
    postPrescription,
    getOTP,
    verifyOTP,
    registerUser,
    registerWithoutOTP,
    getResetPasswordOTP,
    updatePassword,
    getPrescriptionByOrderId,
    getOffers,
    getOffersInOrder,
    getWishlistId,
    addToWishlist,
    removeFromWishlist,
    logout,
    postComplain,
    attachPrescriptions,
    postRequsetOrder,
    removeOrderPrescription,
    removePrescription,
    removeRequestPrescription,
    getRequestedOrder,
    getRequestOrderPrescription,
    getProductsByCategory,
    getRelatedProducts,
    getRequestOrderItems,
    getUserInfo,
    searchProducts,
    getProduct,
    markNotificationAsSeen,
    getNotifications,
    getAddresses,
    saveAddress,
    login,
    getAllSearchedProduct,
    getCurrentInfo,
    getOrder
}
