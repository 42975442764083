// custom components
import IconText from '../../utilities/IconText/IconText';
import icons from '../../../icons/icons';
import { forwardRef } from 'react';

const NavTop = forwardRef((props, ref) => {
  return (
    <section className='bg-nav-top'>
      <nav className='nav-top' ref={ref}>
        <div className='nav-top__contacts'>
          <IconText
            icon={icons.phone}
            text={'+8801319719753'}
            size={'small'}
            type={'default'}
            href={'tel:+8801319719753'}
          />
          <IconText
            icon={icons.mail}
            text={'finepharmaltd@gmail.com'}
            size={'small'}
            type={'default'}
            href={'mailto:finepharmaltd@gmail.com'}
          />
        </div>
        <div className='nav-top__download'>
        <IconText
            text={'Fine Pharma, Fine Life!'}
            size={'small'}
            type={'secondary'}
          />
        </div>
      </nav>
    </section>
  );
});

export default NavTop;
