import { createContext } from "react";

const CartContext = createContext({
    products: [],
    amount: 0,
    discountedAmount: 0,
    addProduct: (product) => { },
    removeProduct: (product) => { },
    updateProductQuantity: (product) => { },
    clearCart: () => {},
    isExist: (id = '') => {return false},
    getCartProduct: (id = '') => {return {}},
    updateProducts: (products = []) => {}
  });

export default CartContext;