import { Fragment, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../../../../store/auth-context";
import Button from "../../../utilities/Button/Button";
import bellIcon from "../../../../icons/e-bell.svg";
import documentIcon from "../../../../icons/pres2.svg";
import supportIcon from "../../../../icons/emergency2.svg";
import heartIcon from "../../../../icons/black-outline-heart.svg";
import profileIcon from "../../../../icons/e-user.svg";
import "./NavMidLinks.css";
import AuthForms from "../../../AuthForms/AuthForms";
import Popup from "../../../utilities/Popup/Popup";
import CallForOrder from "../../../Popups/PrescriptionSaved/CallForOrder";
import AppContext from "../../../../store/app-context";

const NavMidLinks = ({ links }) => {
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const [authFormIsShown, setAuthFormIsShown] = useState(false);
  const [callForOrderIsVisible, setCallForOrderIsVisible] = useState(false);
  const [to, setTo] = useState("/");
  const appCtx = useContext(AppContext);

  const options = {
    type: "default",
    style: "fill",
    shape: "round",
    size: "normal",
  };

  const authFormCloseHandler = () => {
    setAuthFormIsShown(false);
  };

  const setLoginSuccessHandler = () => {
    setAuthFormIsShown(false);
  };

  const navigate = (to, checkAuth) => {
    if (!authCtx.isLoggedIn && checkAuth) {
      setTo(to);
      setAuthFormIsShown(true);
    } else {
      history.push(to);
    }
  };

  const closeHandler = () => {
    setCallForOrderIsVisible(false);
  };

  const openCallForOrder = () => {
    setCallForOrderIsVisible(true);
  };

  return (
    <Fragment>
      <ul className="nav-mid__links">
        <li
          className="nav-mid__links--link relavtive"
          onClick={navigate.bind(null, "/profile/prescription", true)}
        >
          <Button
            icon={documentIcon}
            options={options}
            title="Upload Prescription"
          />
        </li>
        <li className="nav-mid__links--link" onClick={openCallForOrder}>
          <Button icon={supportIcon} options={options} title="Call for Order" />
        </li>
        <li
          className={`nav-mid__links--link relative`}
          onClick={navigate.bind(null, "/notifications", true)}
        >
          <Button icon={bellIcon} options={options} />
          {appCtx.userInfo.noticeCount > 0 &&
            appCtx.userInfo.noticeCount <
              100 && (<span className="badge">{appCtx.userInfo.noticeCount}</span>)}
          {appCtx.userInfo.noticeCount > 99 && (
            <span className="badge">{"99+"}</span>
          )}
        </li>
        <li
          className="nav-mid__links--link relative"
          onClick={() => navigate("/products/wishlist", true)}
        >
          <Button icon={heartIcon} options={options} />
          {appCtx.wishlist.products?.count > 0 &&
            appCtx.wishlist.products?.count < 100 && (
              <span className="badge">{appCtx.wishlist.products.count}</span>
            )}
          {appCtx.wishlist.products?.count > 99 && (
            <span className="badge">{"99+"}</span>
          )}
        </li>

        <li
          className="nav-mid__links--link"
          onClick={() => navigate("/profile/order/all", true)}
        >
          <Button icon={profileIcon} options={options} data-title={"Profile"} />
        </li>
      </ul>
      {authFormIsShown && (
        <AuthForms
          onClose={authFormCloseHandler}
          onLoginSuccess={setLoginSuccessHandler}
          onLoginNavigate={to}
        />
      )}
      {callForOrderIsVisible && (
        <Popup title="Call For Order" onClose={closeHandler}>
          <CallForOrder onOk={closeHandler} />
        </Popup>
      )}
    </Fragment>
  );
};

export default NavMidLinks;
