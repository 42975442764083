const complainTypes = [
    {
        type: 'Website',
        id: 1
    }, {
        type: 'Delivery',
        id: 2
    }, {
        type: 'Agent Behaviors',
        id: 3
    }, {
        type: 'Products',
        id: 4
    }, {
        type: 'Others',
        id: 5
    }
]

const notices = [
    "Welcome to Fine Pharma! - Fine Pharma, Fine Life!",
    "Call any time to get your medicine at your door step Hotline: +8801319719753",
    "Get 12% discount on your first order",
];

const orderStatus = {
    pending: 'pending',
    confirmed: "confirmed",
    processing: 'processing',
    delivering: 'delivering',
    delivered: 'delivered',
    cancel: "cancelled",
    refunded: "Refunded"
}

const storageDict = {
    prevUser: 'user',
    user: 'USERV1.0',
    prevCart: ['CARTV1.2'],
    cart: 'CARTV1.2',
    prevCheckout: ['CHECKOUTV1.0'],
    checkout: 'CHECKOUTV1.0',
    invoice: 'INVOICEV1.0' 
}

const productTypes = {
    feature: "Featured"
}

const addressTypes = {
    home: "Home",
    office: "Office",
    homeTown: "Home Town"
}

const divisions = [
    { id: 10, name: "Barisal" },
    { id: 20, name: "Chittagong" },
    { id: 30, name: "Dhaka" },
    { id: 40, name: "Khulna" },
    { id: 50, name: "Rajshahi" },
    { id: 55, name: "Rangpur" },
    { id: 60, name: "Sylhet" },
    { id: 80, name: "Mymensing" },
  ];

export {
    complainTypes,
    notices,
    orderStatus,
    storageDict,
    productTypes,
    addressTypes,
    divisions
}