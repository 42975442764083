import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';

const Slider = ({ items, Template, options }) => {
  return (
    <Splide options={options}>
      {items.map((item, i) => (
        <SplideSlide key={item.Id || i}>
          <Template item={item} />
        </SplideSlide>
      ))}
    </Splide>
  );
};

export default Slider;
