const APP_DATA = window.appData;

const getCategories = () => {
    return APP_DATA[0];
}

const getProductsByCategory = (categoryId) => {
    return APP_DATA[1].find(data => data[0] === categoryId);
}

const getCategoryDisplay = (pageNumber) => {
    const pageSize = 5;
    const rows = JSON.parse(JSON.stringify(APP_DATA[1]));

    if (!pageNumber) return { length: rows.length, items: rows };

    if (pageNumber > Math.ceil(rows.length / pageSize)) return { length: rows.length, items: [] };

    const takenRows = [...rows.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)];

    takenRows.forEach(row => {
        row[2] = row[2].map(r => {
            const [Id,
                Name,
                GenericName,
                Strength,
                UnitSalePrice,
                Discount,
                ImageName,
                ImageType,
                Category,
                Supplier,
                A, B, C,
                IsOutOfStock] = r; return {
                    Id,
                    Name,
                    GenericName,
                    Strength,
                    UnitSalePrice,
                    Discount,
                    ImageName,
                    ImageType,
                    Category,
                    Supplier,
                    IsOutOfStock
                }
        });
    });

    return { length: rows.length, items: takenRows };
}

const getDisplayBanner = () => {
    return APP_DATA[3];
}

const getNotices = () => {
    return APP_DATA[5];
}

const ProductArr2Obj = (arr) => {
    const [
        Id,
        Name,
        GenericName,
        Strength,
        Price,
        Discount,
        ImgName,
        ImgType,
        Manufacturer
    ] = arr;
    var MRP = Price;

    if (Discount > 0) {
        MRP -= MRP * Discount / 100;
    }

    return {
        Id,
        Name,
        GenericName,
        Strength,
        Price,
        Discount,
        MRP,
        ImgName,
        ImgType,
        Manufacturer,
        Quantity: 1
    }
};

const isExist = (list, item) => {
    for (let i = 0; i < list.length; ++i) {
        if (list[i][0] === item)
            return true;
    }

    return false;
}

export {
    ProductArr2Obj,
    getCategories,
    getProductsByCategory,
    getCategoryDisplay,
    getDisplayBanner,
    isExist,
    getNotices
};