import "./SearchResult.css";

const SearchResult = ({
  items,
  ResultTemplate,
  onClick,
  onSelect,
  isLoading
}) => {
  return (
    <div className="search-box__result">
      <ResultTemplate
        onClick={onClick}
        products={items}
        isLoading={isLoading}
      ></ResultTemplate>
    </div>
  );
};

export default SearchResult;
