import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import AppContext from '../../../../store/app-context';
import Button from '../../../utilities/Button/Button';
import SearchedProduct from '../SearchedProduct/SearchedProduct';

const SearchedProducts = ({products, onClick, isLoading}) => {
  const history = useHistory();
  const {searchQuery: searchCtx} = useContext(AppContext);
  
  if(isLoading){
    return (
      <div className='search-result__no-product-message'>
        Searching...
      </div>
    );
  }

  if (products.length === 0) {
    return (
      <div className='search-result__no-product-message'>
        No Product Found! &nbsp; &#128532;
      </div>
    );
  }

  const gotoSearchResult = () => {
    history.push(`/Products/Search?query=${searchCtx.searchQuery}`);
    onClick();
  }

  return (
    <div>
      {products.map((product, i) => (
        <SearchedProduct product={product} key={i} onClick={onClick} />
      ))}
      {products.length > 5 && (
        <Button
            text={'View all result'}
            options={
                {
                    size: 'full',
                    type: 'primary',
                    style: 'fill'
                }
            }
            click={gotoSearchResult}
        />
      )}
    </div>
  );
};

export default SearchedProducts;
