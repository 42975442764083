import classes from "./LoadingText.module.css";

const LoadingText = ({ className }) => {
  return (
    <div className={className}>
      <div className={classes.loadingText} >
        <div className={classes["load-10"]}>
          <div className={classes["bar"]}></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingText;
