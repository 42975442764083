import React from "react";

const CheckoutContext = React.createContext({
  summaryIsValid: false,
  addressIsValid: false,
  paymentIsValid: false,
  address: { id: null, districtId: null, fullAddress: {}, type: 'Home' },
  addressSelectHandler: (id, districtId, type) => { },
  deliveryCharge: 0,
  onDeliveryChangeChange: (cost) => { },
  payOption: '',
  onPayOptionChange: (payOption) => { },
  from: 'Cart Order',
  storeFrom: (formName) => { },
  autoOrderIsActive: false,
  autoOrderChangeHandler: () => { },
  remarks: '',
  onRemarksChange: (text) => { },
  prescriptions: [],
  storePrescription: (file) => { },
  removePresciption: (id) => { },
  storeCoupon: ({ id = '', code = '', discountAmount = 0, disountType = '' }) => { },
  coupon: { id: '', code: '', discountAmount: 0, disountType: '' },
  resetCheckout: () => {}
});


export default CheckoutContext;