const CallForOrder = ({onOk}) => {
    return(
        <div>
            <p className='mb-16 t-bold t-18'>Please call to bellow number to make an order</p>
            <a href='tel:+8801319719753' className='t-secondary t-24 mb-16 t-center t-hover-underline block'>+8801319719753</a>
            <div className='flex justify-end'>
                <button className='brick primary fill round-corner' onClick={onOk}>Ok</button>
            </div>
        </div>
    )
}

export default CallForOrder;