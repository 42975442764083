import classses from './CategorySlider.module.css';
import { imgUrl } from '../../services/ImgService';

const CategorySlider = ({item}) => {
    return (
        <div className={classses.slider}>
            <img src={imgUrl(item[1])} alt="" />
        </div>
    )
}

export default CategorySlider;