import InputControl from "../../utilities/InputControl/InputControl";
import { useState, useCallback, Fragment } from "react";
import { isEmpty, requestOTP } from "../../../helpers/utilies";
import { http } from "../../../services/httpService";
import { endpoint } from "../../../lib/endpoints";
import Alert from "../../utilities/Alert/Alert";
import LoadingText from "../../Suspenses/LoadingText";

const ResetPassword = ({ onLoginClickHandler, onResetSubmitHandler }) => {
  // Variables
  const [alert, setAlert] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [retryPassword, setRetryPassword] = useState("");

  const [phoneIsTouched, setPhoneIsTouched] = useState(false);
  const [passwordIsTouched, setPasswordIsTouched] = useState(false);
  const [retryPasswordIsTouched, setRetryPasswordIsTouched] = useState(false);

  const phoneIsValid = !isEmpty(phone);
  const passwordIsValid = password.length > 4;

  const phoneInputIsInvalid = phoneIsTouched && !phoneIsValid;
  const passwordInputIsInvalid = passwordIsTouched && !passwordIsValid;
  const retryPasswordInputIsInvalid =
    retryPasswordIsTouched && password !== retryPassword;

  const phoneChangeHandler = ({ target }) => {
    setPhone(target.value.trim());
  };
  const passwordChangeHandler = ({ target }) => {
    setPassword(target.value);
  };
  const retryPasswordChangeHandler = ({ target }) => {
    setRetryPassword(target.value);
  };

  const phoneBlurHandler = () => {
    setPhoneIsTouched(true);
  };
  const passwordBlurHandler = () => {
    setPasswordIsTouched(true);
  };
  const retryPasswordBlurHandler = () => {
    setRetryPasswordIsTouched(true);
  };

  const resetPassword = () => {
    if (!passwordIsValid) {
      setPasswordIsTouched(true);
    }

    if (passwordIsValid !== retryPassword) {
      setRetryPasswordIsTouched(true);
    }

    if (!phoneIsValid) {
      setPhoneIsTouched(true);
    }

    if (!passwordIsValid || password !== retryPassword || !phoneIsValid) {
      return false;
    }

    getOTP(phone, password);

    setPhoneIsTouched(false);
    setRetryPasswordIsTouched(false);
    setPasswordIsTouched(false);
  };

  const getOTP = useCallback(
    (phone, password) => {
      http.post({
        url: endpoint.getResetPasswordOTP,
        payload: {
          Phone: phone,
          ActivityId: window.ActivityId,
        },
        before: () => {
          setIsLoading(true);
        },
        successed: (data) => {
          onResetSubmitHandler(data, phone, password, requestOTP.resetPassword);
        },
        failed: (data, message) => {
          setAlert({ type: "warn", text: message });
        },
        always: () => {
          setIsLoading(false);
        },
        map: (data) => {
          return data;
        },
      });
    },
    [onResetSubmitHandler]
  );

  const alertCloseHandler = () => {
    setAlert(undefined);
  };

  return (
    <Fragment>
      <div>
        <div className="mb-12">
          <div className="form__control mb-8">
            <InputControl
              name={"phone"}
              label={"Phone Number"}
              error={phoneInputIsInvalid && "Phone is required"}
              required
              value={phone}
              onChange={phoneChangeHandler}
              onBlur={phoneBlurHandler}
            />
          </div>
          <div className="form__control mb-8">
            <InputControl
              name={"password"}
              label={"Password"}
              error={
                passwordInputIsInvalid &&
                "Password required minimum 4 characters"
              }
              required
              type="password"
              value={password}
              onChange={passwordChangeHandler}
              onBlur={passwordBlurHandler}
            />
          </div>
          <div className="form__control mb-8">
            <InputControl
              name={"confirm-password"}
              label={"Confirm password"}
              error={retryPasswordInputIsInvalid && "Password not matched"}
              required
              type="password"
              value={retryPassword}
              onChange={retryPasswordChangeHandler}
              onBlur={retryPasswordBlurHandler}
            />
          </div>
        </div>
        <div style={{ minHeight: 12 }}>
          {isLoading && <LoadingText className="flex justify-center mb-8" />}
        </div>
        <div className="flex justify-between align-end">
          <div className="">
            <p className="t-bold t-14 mb-4">
              <span
                className="t-secondary t-hover-underline pointer ml-4"
                onClick={onLoginClickHandler}
              >
                LOGIN
              </span>
            </p>
          </div>
          <div>
            <button
              type="button"
              onClick={resetPassword}
              className="brick fill primary round-corner"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      {alert && (
        <Alert
          type={alert.type}
          text={alert.text}
          onClose={alertCloseHandler}
        ></Alert>
      )}
    </Fragment>
  );
};

export default ResetPassword;
