import CartItem from "../CartItem/CartItem";
import { useContext } from "react";
import CartContext from "../../../store/cart-context";
const CartItems = ({products}) => {
  const cartCtx = useContext(CartContext);

  const removeProductHandler = (product) => {
    cartCtx.removeProduct(product)
  };

  const updateProductQuantiyHandler = (product) => {
    cartCtx.updateProductQuantity(product)
  }

  if (products.length === 0) {
    return (
      <div className="cart--expan__items--empty-message bg-foreground">
        Your shopping cart is empty!
      </div>
    );
  }

  return (
    <div className="cart--expan__items p-16 bg-foreground">
      {products.length !== 0 &&
        products.map((product, i) => (
          <CartItem
            product={product}
            key={i}
            onProductRemove={removeProductHandler}
            onProductQuantityChange={updateProductQuantiyHandler}
          />
        ))}
    </div>
  );
};

export default CartItems;
