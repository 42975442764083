import payment from "../../../images/card4.png";
import payment1 from "../../../images/card4 (1).png";

export const FooterPayments = () => {
  return (
    <div className="footer__content--payments">
      <h3 className='t-uppercase mb-8'>Payement Methods</h3>
      <div className="footer__content--payments__methods">
        <div className="footer__content--payments__methods--method">
          <img src={payment1} alt="" />
        </div>
        <div className="footer__content--payments__methods--method">
          <img src={payment} alt="" />
        </div>
      </div>
    </div>
  );
};
