import { Fragment, useRef, useState } from "react";
import Alert from "../../utilities/Alert/Alert";

const CartItem = ({ product, onProductRemove, onProductQuantityChange }) => {
  const quantityRef = useRef();
  const [alert, setAlert] = useState(undefined);

  const quantityIncreament = ({ target }) => {
    onProductQuantityChange({ ...product, quantity: product.quantity + 1 });
  };

  const quantityDecreament = ({ target }) => {
    if(product.quantity === 1) return false
    onProductQuantityChange({ ...product, quantity: product.quantity - 1 });
  };

  const removeItem = () => {
    onProductRemove(product);
  };

  const quantityHandler = ({ target }) => {
    onProductQuantityChange({ ...product, quantity: +target.value });
  };

  const quantityBlurHandler = ({ target }) => {
    if (+target.value === 0) {
      setAlert({ type: "caution", text: "Qunatity can't be less than 1" });
    }
  };

  const alertCloseHandler = () => {
    setAlert(undefined);
    onProductQuantityChange({ ...product, quantity: 1 });
    quantityRef.current.focus();
  };

  return (
    <Fragment>
      <div className="">
        <p className="t-16 t-bold">{product.name} {product.strength} {product.category}</p>
        <p className="t-12 t-lowercase t-lead mb-4">{product.genericName}</p>
        <p className="t-14 mb-4">
          <span className="t-bold mb-4">MRP (each)</span>:{" "}
          <span className="t-bold">{product.MRP.toMoney()} Tk</span>
        </p>
        <div className="flex justify-start g-8">
          <div className="fx justify-center gc-4">
            <button
              className="brick primary small"
              onClick={quantityDecreament}
            >
              &#10134;
            </button>
            <input
              type="number"
              value={product.quantity}
              onChange={quantityHandler}
              ref={quantityRef}
              onBlur={quantityBlurHandler}
              className="w-80 t-center"
            />
            <button
              className="brick primary small"
              onClick={quantityIncreament}
            >
              &#10133;
            </button>
          </div>
          <button className="brick fill danger small" onClick={removeItem}>
            Remove
          </button>
        </div>
      </div>
      {alert && (
        <Alert
          type={alert.type}
          text={alert.text}
          onClose={alertCloseHandler}
        />
      )}
    </Fragment>
  );
};

export default CartItem;
