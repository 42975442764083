import { Link } from "react-router-dom";

import cart from "../../icons/cart-filled.svg";
import stockOut from "../../icons/stock-out.svg";
import { productImg, imgType } from "../../services/ImgService";
import { Fragment, useContext, useRef, useState } from "react";
import CartContext from "../../store/cart-context";
import placeHolder from "../../images/product-placeholder.png";
import WishlistButton from "../WishlistButton/WishlistButton";
import { calcCouponPercent } from "../../helpers/utilies";
import AnimatedProduct from "../AnimatedProduct/AnimatedProduct";

const ProductCard = ({ item }) => {
  const [anime, setAnime] = useState(false);
  const cardRef = useRef();
  const cartCtx = useContext(CartContext);
  const onAddtoCart = (e) => {
    e.preventDefault();

    if (!cartCtx.isExist(item.Id)) {
      setAnime(true);
    }

    cartCtx.addProduct({
      id: item.Id,
      name: item.Name,
      genericName: item.GenericName,
      strength: item.Strength,
      price: item.UnitSalePrice,
      discount: item.Discount,
      imgName: item.ImageName,
      imgTypeCode: item.ImageType,
      supplier: item.Supplier,
      quantity: 1,
      category: item.Category,
      isPrescriptionRequired: item.IsPrescriptionRequired,
    });
  };

  const stopAnime = () => {
    setAnime(false);
  };

  return (
    <Fragment>
      <div className="product-card p-2 h-100p" ref={cardRef}>
        <Link
          to={`/product/details/${item.Id}`}
          className=" p-8 round-corner kill-anchore flex justify-between g-2 t-uppercase column relative h-100p p-2 bg-foreground shadow-lite hover-b-primary"
        >
          <div className="flex column justify-start h-100p mb-8 g-4">
            <div className="img overflow-hidden" style={{ height: 210 }}>
              <img
                src={
                  productImg(
                    item.ImageName || item.ImagePath,
                    item.ImageType,
                    imgType.sm
                  ) || placeHolder
                }
                alt={
                  item.ImageName || item.ImagePath
                    ? item.ImageName || item.ImagePath
                    : "No Product Image"
                }
                className="transition-ease w-max product-card__img"
              />
            </div>
            <div className="details flex-full flex column justify-between">
              <div>
                <p className="name t-bolder t-14" title={item.Name}>
                  {`${item.Name} ${
                    item.Strength?.trim() === "." ? "" : item.Strength?.trim()
                  } ${item.Category}`}
                </p>
                <p
                  className="generic-name t-limited t-lead t-12 t-uppercase t-primary"
                  style={{ fontSize: 10 }}
                >
                  {item.GenericName}
                </p>
                <p className="t-12 line-height t-limited">{item.Supplier}</p>
              </div>
              <div className="price flex g-8 align-end mt-8 mb-8">
                <p className="current t-bolder t-18">
                  {item.UnitSalePrice - item.Discount}Tk
                </p>

                {item.Discount > 0 && (
                  <p className="original t-line-thro t-danger t-14 t-bold">
                    {item.UnitSalePrice}Tk
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-between align-center g-8 fx-hor-between-ver-center">
            <WishlistButton id={item.Id} />
            {item.IsOutOfStock && (
              <button className="flex justify-between align-center oval g4 p-4 px-8 t-white out-of-stock">
                <img src={stockOut} alt="" className="w-20" />
                <span className="text max-content ">
                  Stock Out
                </span>
              </button>
            )}

            {!item.IsOutOfStock && (
              <button
                className="flex justify-between align-center btn-primary oval g4 p-4 px-8 t-white add-to-cart"
                onClick={onAddtoCart}
              >
                <img src={cart} alt="" className="w-20" />
                <span className="text max-content">Add to Cart</span>
              </button>
            )}
          </div>
          {item.Discount > 0 && (
            <div className="t-14 t-bold t-white absolute bg-primary p-2 right-top">
              {calcCouponPercent(item.Discount, item.UnitSalePrice).toFixed(2)}%
            </div>
          )}
        </Link>
      </div>
      {anime && (
        <AnimatedProduct when={anime} onStop={stopAnime} uiRef={cardRef} />
      )}
    </Fragment>
  );
};

export default ProductCard;
