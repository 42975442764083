import Slider from "../../../utilities/Slider/Slider";
import ProductCard from "../../../ProductCard/ProductCard";
import { useHistory } from "react-router-dom";
import { imgUrl } from "../../../../services/ImgService";
import BannerTemplate from "../../../Banner/BannerTemplate/BannerTemplate";
import CategorySlider from "../../../CategorySlider/CategorySlider";

const CategoryRow = ({ categoryWithProduct, i }) => {
  let [id, category, products] = categoryWithProduct;
  
  const history = useHistory();

  const sliderItems = (number) => {
    return categoryWithProduct[2].length < number
      ? categoryWithProduct[2].length
      : number;
  };

  const options = {
    rewind: true,
    type: "slide",
    autoplay: categoryWithProduct[2].length > 4,
    rewindSpeed: 1000,
    speed: 1000,
    resetProgress: true,
    perPage: sliderItems(6),
    // perMove: 2,
    // slider: false,
    gap: "0.5rem",
    width: '100%',
    minWidth: '100%',

    breakpoints: {
      1150: {
        perPage: sliderItems(6),
      },
      1000: {
        perPage: sliderItems(5),
      },
      860: {
        perPage: sliderItems(4),
      },
      714: {
        perPage: sliderItems(3)
      },
      400: {
        perPage: sliderItems(2)
      }
    },
  };

  const sliderOptions = {
    rewind: true,
    type: "slide",
    autoplay: true,
    rewindSpeed: 1000,
    speed: 500,
  };


  options.autoplay = categoryWithProduct[2].length > options.perPage;

  const gotoProducts = () => history.push(`/products/category/${id}`);

  return (
    <div className="category-row container round-corner bg-background mt-16">
     {categoryWithProduct[3].length > 0 && <div className="mb-16">
     <Slider
        items={categoryWithProduct[3]}
        Template={CategorySlider}
        options={sliderOptions}
      />
      </div>}
      <div className="mb-8 flex justify-between align-end">
        <h2 className="t-18 t-bold t-primary t-uppercase"><strong>{category}</strong></h2>
        <button
          className="brick primary fill round-corner small"
          onClick={gotoProducts}
        >
          View All
        </button>
      </div>
      <div>
        <Slider items={products} options={options} Template={ProductCard} />
      </div>
    </div>
  );
};

export default CategoryRow;
