import { Fragment, useCallback, useState, useContext } from "react";
import InputControl from "../../utilities/InputControl/InputControl";
import { http } from "../../../services/httpService";
import { endpoint } from "../../../lib/endpoints";
import { isEmpty } from "../../../helpers/utilies";
import Alert from "../../utilities/Alert/Alert";
import LoadingText from "../../Suspenses/LoadingText";
import AuthContext from "../../../store/auth-context";
import { useHistory } from "react-router-dom";


const Register = ({ onLoginClickHandler, onRegistrationSubmitHandler, onLoginNavigate, onSuccess }) => {
  const {onLogin} = useContext(AuthContext);
  const history = useHistory();

  const [alert, setAlert] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [retryPassword, setRetryPassword] = useState("");

  const [phoneIsTouched, setPhoneIsTouched] = useState(false);
  const [passwordIsTouched, setPasswordIsTouched] = useState(false);
  const [retryPasswordIsTouched, setRetryPasswordIsTouched] = useState(false);

  const phoneIsValid = !isEmpty(phone);
  const passwordIsValid = password.length > 4;

  const phoneInputIsInvalid = phoneIsTouched && !phoneIsValid;
  const passwordInputIsInvalid = passwordIsTouched && !passwordIsValid;
  const retryPasswordInputIsInvalid =
    retryPasswordIsTouched && password !== retryPassword;

  const phoneChangeHandler = ({ target }) => {
    setPhone(target.value.trim());
  };
  const passwordChangeHandler = ({ target }) => {
    setPassword(target.value);
  };
  const retryPasswordChangeHandler = ({ target }) => {
    setRetryPassword(target.value);
  };

  const phoneBlurHandler = () => {
    setPhoneIsTouched(true);
  };
  const passwordBlurHandler = () => {
    setPasswordIsTouched(true);
  };
  const retryPasswordBlurHandler = () => {
    setRetryPasswordIsTouched(true);
  };

  const register = () => {
    if (!passwordIsValid) {
      setPasswordIsTouched(true);
    }

    if (passwordIsValid !== retryPassword) {
      setRetryPasswordIsTouched(true);
    }

    if (!phoneIsValid) {
      setPhoneIsTouched(true);
    }

    if (!passwordIsValid || password !== retryPassword || !phoneIsValid) {
      return false;
    }

    // getOTP(phone, password);
    registerUser(phone, password);

    setPhoneIsTouched(false);
    setRetryPasswordIsTouched(false);
    setPasswordIsTouched(false);
  };

  const login = useCallback(
    (phone, password) => {
      http.post({
        url: endpoint.login,
        payload: {
          UserName: phone,
          Password: password,
        },
        before: () => {
          setIsLoading(true);
        },
        successed: (data) => {
          onLogin(data);
          history.push(onLoginNavigate);
          onSuccess();
        },
        failed: (data, message) => {
          setAlert({ type: "warn", text: message });
        },
        always: () => {
          setIsLoading(false);
        },
        map: (data) => {
          return data;
        }
      });
    },
    [onSuccess, onLoginNavigate, history, onLogin]
  );

  const registerUser = useCallback(
      (phone, password) => {
        http.post({
          url: endpoint.registerWithoutOTP,
          payload: {
            Name: '',
            Phone: phone,
            Password: password,
            Email: '',
            ActivityId: window.ActivityId,
          },
          before: () => {
            setIsLoading(true);
          },
          successed: (data) => {
            login(phone, password);
          },
          failed: (data, message) => {
            setAlert({ type: "warn", text: message });
          },
          always: () => {
            setIsLoading(false);
          },
          map: (data) => {
            return data;
          },
        });
      },
      [login]
    );
   

  // const getOTP = useCallback(
  //   (phone, password) => {
  //     http.post({
  //       url: endpoint.getOTP,
  //       payload: {
  //         Phone: phone,
  //         ActivityId: window.ActivityId,
  //       },
  //       before: () => {
  //         setIsLoading(true);
  //       },
  //       successed: (data) => {
  //         onRegistrationSubmitHandler(
  //           data,
  //           phone,
  //           password,
  //           requestOTP.registration
  //         );
  //       },
  //       failed: (data, message) => {
  //         setAlert({ type: "warn", text: message });
  //       },
  //       always: () => {
  //         setIsLoading(false);
  //       },
  //       map: (data) => {
  //         return data;
  //       },
  //     });
  //   },
  //   [onRegistrationSubmitHandler]
  // );

  const alertCloseHandler = () => {
    setAlert(undefined);
  };
  return (
    <Fragment>
      <div>
        <div className="form__control mb-2">
          <InputControl
            name={"phone"}
            label={"Phone Number"}
            error={phoneInputIsInvalid && "Phone is required"}
            required
            value={phone}
            onChange={phoneChangeHandler}
            onBlur={phoneBlurHandler}
          />
        </div>
        <div className="form__control mb-2">
          <InputControl
            name={"password"}
            label={"Password"}
            error={
              passwordInputIsInvalid && "Password required minimum 4 characters"
            }
            required
            type="password"
            value={password}
            onChange={passwordChangeHandler}
            onBlur={passwordBlurHandler}
          />
        </div>
        <div className="form__control mb-2">
          <InputControl
            name={"confirm-password"}
            label={"Confirm password"}
            error={retryPasswordInputIsInvalid && "Password not matched"}
            required
            type="password"
            value={retryPassword}
            onChange={retryPasswordChangeHandler}
            onBlur={retryPasswordBlurHandler}
          />
        </div>
        <div style={{ minHeight: 12 }}>
          {isLoading && <LoadingText className="flex justify-center mb-8" />}
        </div>
        <div className="flex justify-between align-end">
          <div className="">
            <p className="t-bold t-14 mb-4">
              Have Account?
              <span
                className="t-secondary t-hover-underline pointer ml-4"
                onClick={onLoginClickHandler}
              >
                LOGIN
              </span>
            </p>
          </div>
          <div>
            <button
              type="button"
              onClick={register}
              className="brick fill primary round-corner"
            >
              Register
            </button>
          </div>
        </div>
      </div>
      {alert && (
        <Alert
          type={alert.type}
          text={alert.text}
          onClose={alertCloseHandler}
        ></Alert>
      )}
    </Fragment>
  );
};

export default Register;
