import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { AuthContextProvider } from './store/auth-context';
import CheckoutContextProvider from './store/CheckoutContextProvider';
import CartContextProvider from './store/CartContextProvider';
import AppContextProvider from './store/AppContextProvider';

ReactDOM.render(
  <BrowserRouter>
    <AppContextProvider>
      <CartContextProvider>
        <CheckoutContextProvider>
          <AuthContextProvider>
            <App />
          </AuthContextProvider>
        </CheckoutContextProvider>
      </CartContextProvider>
    </AppContextProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

