import { useCallback, useContext, useState } from "react";
import Login from "./Login/Login";
import Register from "./Registration/Registration";
import Popup from "../utilities/Popup/Popup";
import VerifyOTP from "./VerifyOTP/VerifyOTP";
import ResetPassword from "./ResetPassword/ResetPassword";
import AppContext from "../../store/app-context";
import { http } from "../../services/httpService";
import { endpoint } from "../../lib/endpoints";

const AuthForms = ({ onClose, onLoginSuccess, onLoginNavigate }) => {
  const appCtx = useContext(AppContext);

  const { storeProducts } = appCtx.wishlist;
  const { update } = appCtx.userInfo;
  const getUserInfo = useCallback(() => {
    http.get({
      url: endpoint.getUserInfo,
      successed: (data) => {
        storeProducts(data.wishlist);
        update(data.notificationCount, data.pendingCash, data.fineCash);
      },
      map: (data) => {
        const wishlist = {};
        let count = 0;
        data.Data.WishListId.forEach((item) => {
          wishlist[item.Id] = item.Id;
          count++;
        });

        return {
          wishlist: { ...wishlist, count },
          notificationCount: data.Data.NotificationCount.NotificationCount,
          pendingCash: data.Data.Customer.Pending,
          fineCash: data.Data.Customer.Cashback,
        };
      },
    });
  }, [storeProducts, update]);

  const loginSuccessHandler = useCallback(() => {
    getUserInfo();
    onLoginSuccess();
  }, [getUserInfo, onLoginSuccess]);

  // const openRegisterForm = () => {
  //   setAuthForm({
  //     form: (
  //       <Register
  //         onLoginClickHandler={openLoginForm}
  //         onRegistrationSubmitHandler={openVerifyOTPForm}
  //         onLoginNavigate={onLoginNavigate}
  //       />
  //     ),
  //     title: "Registration",
  //   });
  // };

  const openRegisterForm = () => {
    setAuthForm({
      form: (
        <Register
          onLoginClickHandler={openLoginForm}
          onRegistrationSubmitHandler={openVerifyOTPForm}
          onLoginNavigate={onLoginNavigate}
          onSuccess={loginSuccessHandler}
        />
      ),
      title: "Registration",
    });
  };

  const openLoginForm = () => {
    setAuthForm({
      form: (
        <Login
          onRegistrationClickHandler={openRegisterForm}
          onResetPasswordClickHandler={openResetPasswordForm}
          openLoginForm={openLoginForm}
          onSuccess={loginSuccessHandler}
          onLoginNavigate={onLoginNavigate}
        />
      ),
      title: "Login",
    });
  };

  const openVerifyOTPForm = useCallback(
    (otpModel, phone, password, verifyFOr) => {
      setAuthForm({
        form: (
          <VerifyOTP
            OTPModel={otpModel}
            phone={phone}
            password={password}
            onSuccess={loginSuccessHandler}
            onLoginNavigate={onLoginNavigate}
            verifyFor={verifyFOr}
          />
        ),
        title: "Verify Phone Number",
      });
    },
    [onLoginNavigate, loginSuccessHandler]
  );

  const openResetPasswordForm = () => {
    setAuthForm({
      form: (
        <ResetPassword
          onLoginClickHandler={openLoginForm}
          onResetSubmitHandler={openVerifyOTPForm}
          onSuccess={loginSuccessHandler}
          onLoginNavigate={onLoginNavigate}
        />
      ),
      title: "Reset Password",
    });
  };

  const [authForm, setAuthForm] = useState({
    form: (
      <Login
        onRegistrationClickHandler={openRegisterForm}
        onResetPasswordClickHandler={openResetPasswordForm}
        openLoginForm={openLoginForm}
        onSuccess={loginSuccessHandler}
        onLoginNavigate={onLoginNavigate}
      />
    ),
    title: "Login",
  });

  return (
    <Popup
      title={authForm.title}
      BodyComponent={() => authForm.form}
      onClose={onClose}
    />
  );
};

export default AuthForms;
