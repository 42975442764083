import Banner from "../components/Banner/Banner";
import Perks from "../components/Perks/Perks";
import Notice from "../components/Home/Notice/Notice";
import CategoryDisplay from "../components/Home/CategoryDisplay/CategoryDisplay";
import Page from "../Page/Page";

const Home = () => {
  return (
    <Page>
      <Banner />
      <Perks />
      <Notice />
      <CategoryDisplay />
    </Page>
  );
};

export default Home;
