import NavLowLink from './NavLowLink/NavLowLink';

const NavLowLinks = ({ links }) => {
  return (
    <div className='nav-bottom__items__links'>
      {links.map((link) => (
        <NavLowLink link={link} key={link.text} />
      ))}
    </div>
  );
};

export default NavLowLinks;
