import './IconText.css';

const IconText = ({icon, text, size, type, ...rest}) => {
    return (
        <div className={`${size} ${type} icon-text`}>
            <div className='icon-text__icon'>
              <img src={icon} alt={icon} style={{display: icon ? 'block': 'none'}}/>
            </div>
            <a {...rest} className='icon-text__text block pointer'>{text}</a>
          </div>
    );
}

export default IconText;