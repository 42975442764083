import './BannerTemplate.css'
import { bannerImg,imgType } from '../../../services/ImgService';

const BannerTemplate = ({item}) => {
    return (
        <div className='banner-template'>
            <img src={bannerImg(item[0],imgType.origin)} alt="" />
        </div>
    )
}

export default BannerTemplate;