import "./OpenCart.css";
import CartItems from "../CartItems/CartItems";
import { Fragment, useContext, useState } from "react";
import { useHistory } from "react-router";
import AuthContext from "../../../store/auth-context";
import AuthForms from "../../AuthForms/AuthForms";
import CartContext from "../../../store/cart-context";
import cart from "../../../icons/cart-filled.svg";

const OpenCart = ({ closeCart }) => {
  const cartCtx = useContext(CartContext);
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  const [authFormIsShown, setAuthFormIsShown] = useState(false);

  const authFormCloseHandler = () => {
    setAuthFormIsShown(false);
  };

  const loginSuccessHandler = () => {
    closeCart();
  };

  const clearCartHandler = () => {
    cartCtx.clearCart();
    closeCart();
  };

  const navigate = () => {
    if (cartCtx.products.length === 0) {
      alert("No product added to the cart");
      return false;
    }

    if (!authCtx.isLoggedIn) {
      setAuthFormIsShown(true);
    } else {
      history.push("/checkout");
      closeCart();
    }
  };

  return (
    <Fragment>
      <div className="cart--expan grid bg-foreground">
        <div className="cart--expan__header">
          <div className="flex justify-start align-center g-8 p-8 t-initial">
            <span className="t-18">
              <img src={cart} alt="" className="w-20" />
            </span>{" "}
            {cartCtx.products.length} Item(s)
          </div>
          <div className="cart--expan__header--close" onClick={closeCart}>
            X
          </div>
        </div>

        <CartItems products={cartCtx.products} />

        <div className="cart--expan__calc">
          <div className="cart--expan__calc--item-count">
            <div className="cart--expan__calc--item-count__text">
              Total Items
            </div>
            <div className="cart--expan__calc--item-count__number">
              {cartCtx.products.length}
            </div>
          </div>
          <div className="cart--expan__calc--total-amount">
            <div className="cart--expan__calc--total-amount__text">
              Total Amount
            </div>
            <div className="cart--expan__calc--total-amount__number">
              {cartCtx.discountedAmount.toMoney()} Tk
            </div>
          </div>
        </div>
        <div className="flex justify-center g-8 p-8 extra">
          <button className="brick fill primary w-full" onClick={navigate}>
            Order Now
          </button>
          <button
            className="brick fill secondary w-full"
            onClick={clearCartHandler}
          >
            Clear Cart
          </button>
        </div>
      </div>

      {authFormIsShown && (
        <AuthForms
          onClose={authFormCloseHandler}
          onLoginSuccess={loginSuccessHandler}
          onLoginNavigate={"/checkout"}
        />
      )}
    </Fragment>
  );
};

export default OpenCart;
