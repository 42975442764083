import { Fragment, useContext, useEffect, useState, useCallback } from "react";
import AppContext from "../../store/app-context";
import heart from "../../icons/heart-outline.svg";
import headerFilled from "../../icons/heart.svg";
import AuthContext from "../../store/auth-context";
import { http } from "../../services/httpService";
import { endpoint } from "../../lib/endpoints";
import Alert from "../utilities/Alert/Alert";

const WishlistButton = ({ id }) => {
  const { wishlist } = useContext(AppContext);
  const [isWished, setIsWished] = useState(false);
  const authCtx = useContext(AuthContext);
  const [alert, setAlert] = useState(false);

  const {isLoggedIn} = authCtx;
  useEffect(() => {
    if (wishlist.products[id]) {
      setIsWished(true);
    } else {
      setIsWished(false);
    }
  }, [setIsWished, wishlist, id, isLoggedIn]);

  const toggleWished = (e) => {
    e.preventDefault();

    if (!authCtx.user?.id) {
      setAlert({type: "warn", text: "please login"});
      return false;
    }

    if (!isWished) {
      addToWishlist(id, authCtx.user?.id);
    } else {
        removeFromWishlist(id, authCtx.user?.id);
    }
  };

  const addToWishlist = useCallback((id, userId) => {
    http.post({
      url: endpoint.addToWishlist,
      payload: {
        ProductId: id,
        CustomerId: userId,
        ActivityId: window.ActivityId
      },
      before: () => {
        setIsWished(true);
      },
      successed: () => {
        wishlist.addProduct(id);
      },
      failed: (data, message) => {
        setAlert({ type: "warn", text: message });
        setIsWished(false);
      },
    });
  }, [wishlist]);

  const removeFromWishlist = useCallback((id, userId) => {
    http.post({
        url: endpoint.removeFromWishlist,
        payload: {
          ProductId: id,
          CustomerId: userId,
          ActivityId: window.ActivityId
        },
        before: () => {
          setIsWished(false);
        },
        successed: () => {
            wishlist.removeProduct(id);
        },
        failed: (data, message) => {
          setAlert({ type: "warn", text: message });
          setIsWished(true);
        },
      });
  }, [wishlist]);

  const aletCloseHandler = () => {
      setAlert(undefined);
  }
  
  return (
    <Fragment>
      <button
        data-title="Add to wishlist"
        className="btn-round wish p-4 center transition-ease p-4 hover-bg-secondary"
        title={isWished ? "Remove from whishlist" : "Add to whishlist"}
        onClick={toggleWished}
      >
        <img src={isWished ? headerFilled : heart} alt="" className="w-20" />
      </button>
      {alert && <Alert type={alert.type} text={alert.text} onClose={aletCloseHandler}/>}
    </Fragment>
  );
};

export default WishlistButton;
