import fb from '../../../../icons/facebook.svg';
import wa from '../../../../icons/whatsapp.svg';
import li from '../../../../icons/linkedin.svg';
import tt from '../../../../icons/twitter.svg';
import ut from '../../../../icons/youtube.svg';
import ig from '../../../../icons/instagram.svg';

export const SocialContent = () => {
    return (
        <div>
        <h3 className='t-uppercase mb-8'>Connect With Us</h3>
        <div className="social-links">
          <div className="social-links__link" title='facebook'>
            <a href="https://www.facebook.com/finepharmaltd" rel="noreferrer" target={'_blank'}><img src={fb} alt="" /></a>
          </div>
          <div className="social-links__link" title='+8801319719753'>
            <a href='tel:+8801319719753'><img src={wa} alt="" /></a>
          </div>
          <div className="social-links__link" title='Youtube'>
            <a href='https://www.youtube.com/channel/UC7WGLV7zyF_ibMXtVKgq_vw' rel="noreferrer" target={'_blank'}><img src={ut} alt="" /></a>
          </div>
          <div className="social-links__link" title='Twitter'>
            <a href='https://twitter.com/finepharmaltd' rel="noreferrer" target={'_blank'}><img src={tt} alt="" /></a>
          </div>
          {/* <div className="social-links__link" title='Instagram'>
            <a href='https://www.instagram.com/finepharmaltd' rel="noreferrer" target={'_blank'}><img src={ig} alt="" /></a>
          </div>
          <div className="social-links__link" title='LinkedIn'>
            <a href='https://www.linkedin.com/company/finepharmaltd' rel="noreferrer" target={'_blank'}><img src={li} alt="" /></a>
          </div> */}
        </div>
      </div>
    )
}
